import { Delete, Info } from '@mui/icons-material';
import {
	Grid,
	IconButton,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	ListSubheader,
	Typography,
} from '@mui/material';
import React from 'react';
import Media from '../Shared/Media/media';

export const ImagesList = (props) => {
	return (
		<ImageList>
			<ImageListItem key="Subheader" cols={2}>
				<Typography variant="h5">Images</Typography>
			</ImageListItem>
			{props.images.map((item) => (
				<>
					<ImageListItem key={item.uuid}>
						<Media
							src={item.url}
							mimeType={
								item.mime[0] === '.' ? item.mime.substring(1) : item.mime
							}
							isimage={item.isImage}
							loading="lazy"
							style={{ width: '100%' }}
						/>
						{/* <ImageListItemBar
							title={item.title}
							subtitle={item.author}
							actionIcon={
								<IconButton
									sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
									aria-label={`delete image`}
								>
									<Delete />
								</IconButton>
							}
						/> */}
					</ImageListItem>
				</>
			))}
		</ImageList>
	);
};
