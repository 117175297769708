import './post.css';

import React from 'react';

import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

import Media from '../Shared/Media/media';
import AvatarGetFile from '../Shared/Media/AvatarGetFile';
import { Avatar, Skeleton } from '@mui/material';
import { deletePost } from '../../store/post';
import { useDispatch } from 'react-redux';
import { deleteFeed } from '../Feed/feedService';

export default function Post({ post, loading }) {
	let postDiscription = post?.desc;
	const dispatch = useDispatch();

	function replaceAll(string, search, replace) {
		return string.split(search).join(replace);
	}

	const replaceUUIDS = (str, mentionedUsers) => {
		for (let i = 0; i < mentionedUsers.length; i++) {
			str = replaceAll(
				str,
				`@(${mentionedUsers[i].uuid})[]`,
				`<b>${mentionedUsers[i].name}</b>`,
			);
		}
		return str;
	};

	if (post && postDiscription && post.mentionedUsers.length > 0) {
		postDiscription = replaceUUIDS(postDiscription, post.mentionedUsers);
	}

	const handleDelete = (uuid) => {
		deleteFeed(uuid).then(dispatch(deletePost(uuid)));
	}

	return (
		<div className="post">
			<div className="postWrapper">
				<div className="postTop">
					<div className="postTopLeft">
						{loading ? (
							<>
								<Skeleton variant="circular">
									<Avatar />
								</Skeleton>
								<Skeleton sx={{ marginLeft: 1 }} variant="text" width={200} />
							</>
						) : (
							<>
								<AvatarGetFile
									src={post.userImage.url}
									mimeType={
										post.userImage.mime[0] === '.'
											? post.userImage.mime.substring(1)
											: post.userImage.mime
									}
									isimage={post.userImage.isImage}
								/>
								<span className="postUsername">{post.username}</span>
								<span className="postDate">{post.date}</span>
								
								<IconButton onClick={()=>{
									handleDelete(post.id);
								}} color="inherit">
									<Delete />
								</IconButton>
							</>
						)}
					</div>
				</div>
				<div className="postCenter">
					{loading ? (
						<>
							<Skeleton variant="text" height={200} />
							<Skeleton variant="rectangle" height={750} />
						</>
					) : (
						<>
							<span
								className="postText"
								dangerouslySetInnerHTML={{ __html: postDiscription }}
							/>
							{post.attachments.length > 0 && (
								<Carousel
									fullHeightHover={true}
									animation="slide"
									autoPlay={false}
									navButtonsProps={{
										style: {
											backgroundColor: 'cornflowerblue',
											borderRadius: 0,
										},
									}}
									sx={{
										textAlign: 'center',
										height: post.attachments.length > 0 ? '750px' : 0,
										marginTop: 5,
									}}
									navButtonsWrapperProps={{
										style: {
											bottom: '0',
											top: 'unset',
										},
									}}
								>
									{post.attachments.map((item) => (
										<Media
											key={item.uuid}
											src={item.url}
											mimeType={
												item.mime[0] === '.'
													? item.mime.substring(1)
													: item.mime
											}
											isimage={item.isImage}
											style={{ height: '700px' }}
										/>
									))}
								</Carousel>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
}
