import React from 'react';
import { Navigate, Routes } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import Main from '../components/Main/Main';
import Sidebar from '../components/Sidebar/Sidebar';
import FeedbackSection from '../components/Feedback/FeedbackSection';

const DashboardRouter = () => {
  const cookies = new Cookies();
  if (!cookies.get('accessToken')) {
    return <Navigate to='/auth/login' replace />;
  }

  return (
    <>
      <Sidebar />
      <Main />
      <Routes />
    </>
  );
};

export default DashboardRouter;
