import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react-dom/test-utils';

const imagesSlice = createSlice({
	name: 'images',
	initialState: {
        value: {},
		sentRequest: []
    },
	reducers: {
		setImages: (state, action) => {
			state.value = action.payload;
		},
		addImage: (state, action) => {
			let { type, payload } = action;
			state.value = { ...state.value, ...payload };
		},
		setSendRequest: (state, action) => {
			let {type, payload} = action;
			state.sentRequest = [...state.sentRequest, payload];
		}
	},
});

export const { setImages, addImage, setSendRequest } = imagesSlice.actions;

export default imagesSlice.reducer;
