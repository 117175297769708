import axios from 'axios';
import { BASE_ENDPOINT, LOGOUT_ENDPOINT } from '../constants/endpoints.api';
import Cookies from 'universal-cookie';
import logOut from './logout';
import qs from 'qs';
import { toast } from 'react-toastify';

export const METHODS = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	DELETE: 'DELETE',
	PATCH: 'PATCH',
};

const globalHeaders = {
	'api-key': process.env.REACT_APP_API_KEY,
};

export const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const cookies = new Cookies();

const axiosInstance = axios.create({
	baseURL: BASE_ENDPOINT,
	timeout: 60000,
	responseType: 'json',
	headers: globalHeaders,
	paramsSerializer: (params) => {
		return qs.stringify(params, { allowDots: true });
	},
});

const axiosDispatcher = (
	method,
	endpoint,
	data,
	params,
	headers,
	auth,
	cancelToken,
	responseType,
) => {
	const requestHeaders = {
		...headers,
		...globalHeaders,
	};
	if (cookies.get('accessToken')) {
		requestHeaders['Authorization'] = `Bearer ${cookies.get('accessToken')}`;
		if (responseType == 'arraybuffer') {
			requestHeaders['Content-Encoding'] = 'gzip';
		}
	}

	const shouldCallLogout =
		cookies.get('accessToken') && endpoint != LOGOUT_ENDPOINT;

	return axiosInstance({
		method,
		url: endpoint,
		data,
		params,
		headers: requestHeaders,
		auth,
		cancelToken: cancelToken?.token,
		responseType: responseType ?? 'json',
		validateStatus: function (status) {
			return status >= 200 && status < 400;
		},
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			let status = undefined;
			if (error.response) {
				status = error.response.status;
				if (error.response.status === 401 && shouldCallLogout) {
					logOut(cancelToken);
					toast.error("You've been unauthenticated, please sign in again.");
				}
				if (error.response.data.data.error)
					error = error.response.data.data.error;
			}
			return Promise.reject({ code: status, error: error });
		});
};

export default axiosDispatcher;
