import React from 'react';
import Table from '../Table/Table';
import {
	changeUserActivation,
	changeUserRole,
	fetchUsers,
} from './usersService';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import User from './user';
import {
	Check,
	Clear,
	ManageAccounts,
	PersonRemove,
} from '@mui/icons-material';
import { Button } from '@mui/material';

const UsersTable = ({ users }) => {
	const [data, setData] = React.useState([]);
	const [currentPage, setCurrentPage] = React.useState(0);
	const [count, setCount] = React.useState(0);
	const [keyword, setKeyword] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [loading, setLoading] = React.useState(true);
	const [selectedUserId, setSelectedUserId] = React.useState(-1);
	const [defaultPageSize, setDefaultPageSize] = React.useState(10);

	const handleClose = () => {
		setOpen(false);
	};

	const onRowClick = (row) => {
		setOpen(true);
		setSelectedUserId(row.id);
	};

	const columns = [
		{ field: 'uuid', headerName: 'ID', width: 70, hide: true },
		{ field: 'name', headerName: 'Name', width: 250 },
		{ field: 'jobTitle', headerName: 'Job Title', width: 130 },
		{ field: 'userSince', headerName: 'User Since', width: 130 },
		{ field: 'martialStatus', headerName: 'Martial status', width: 130 },
		{ field: 'dateOfBirth', headerName: 'Birthdate', width: 130 },
		{ field: 'companyName', headerName: 'Company Name', width: 130 },
		{ field: 'gender', headerName: 'Gender', width: 130 },
		{ field: 'nationality', headerName: 'Nationality', width: 130 },
		{ field: 'phoneNumber', headerName: 'Phone Number', width: 130 },
		{ field: 'accountStatus', headerName: 'Account Status', width: 130 },
		{ field: 'role', headerName: 'Role', width: 130 },
		{
			field: 'actions',
			headerName: 'Actions',
			width: 350,
			renderCell: (params) => {
				const onChangeActiveStatus = (e) => {
					e.stopPropagation(); // don't select this row after clicking
					changeUserActivation(params.row.id, params.row.status != '0').then(
						(response) => {
							let newData = Array.from(data);
							setData(
								newData.map((row) => {
									if (row.id == params.row.id) {
										row.status = !row.status;
										row.accountStatus =
											row.status == '0' ? 'Active' : 'unActive';
									}
									return row;
								}),
							);
						},
					);
				};

				const onChangeUserRole = (e) => {
					e.stopPropagation();
					changeUserRole(params.row.id, params.row.roleBoolean == '0').then(
						(response) => {
							let newData = Array.from(data);
							setData(
								newData.map((row) => {
									if (row.id == params.row.id) {
										row.roleBoolean = !row.roleBoolean;
										row.role = row.roleBoolean == '0' ? 'User' : 'Admin';
									}
									return row;
								}),
							);
						},
					);
				};

				return (
					<>
						<Button
							startIcon={params.row.status == '0' ? <Clear /> : <Check />}
							onClick={onChangeActiveStatus}
							color="inherit"
						>
							{params.row.status == '0' ? 'Deactivate' : 'Activate'}
						</Button>
						<Button
							startIcon={
								params.row.roleBoolean == '0' ? (
									<ManageAccounts />
								) : (
									<PersonRemove />
								)
							}
							onClick={onChangeUserRole}
							color="inherit"
						>
							{params.row.roleBoolean == '0' ? 'Set Admin' : 'Remove Admin'}
						</Button>
					</>
				);
			},
		},
	];

	const loadData = React.useCallback((keyword, pageSize, page) => {
		setLoading(true);
		fetchUsers(keyword, pageSize, page)
			.then((data) => {
				let rows = data.resp.map((element) => {
					return {
						id: element.uuid,
						name: element.name,
						jobTitle: element.position,
						userSince: element.formattedCreatedAt,
						martialStatus: element.martialStatus == '0' ? 'Married' : 'Single',
						accountStatus: element.status == '0' ? 'Active' : 'unActive',
						status: element.status,
						role: element.role == '0' ? 'User' : 'Admin',
						roleBoolean: element.role,
						dateOfBirth:
							(element.dateOfBirth &&
								new Date(Number(element.dateOfBirth) / 1000).toLocaleDateString(
									'en-US',
								)) ||
							element.dateOfBirth,
						education: element.education,
						companyName: element.companyName,
						gender: element.gender == '0' ? 'Male' : 'Female',
						nationality: element.nationality,
						phoneNumber: element.phoneNumber,
					};
				});
				setData(rows);
				setCount(data.count);
				setCurrentPage(page);
				setLoading(false);
			})
			.catch(() => setLoading(false));
	});

	const onFilterModelChange = React.useCallback((filterModel) => {
		if (filterModel.quickFilterValues.length === 0) {
			loadData('', defaultPageSize, currentPage);
		} else {
			loadData(filterModel.quickFilterValues[0], defaultPageSize, currentPage);
		}
	});

	React.useEffect(() => {
		if (users) {
			setData(
				users.map((element) => {
					return {
						id: element.uuid,
						name: element.name,
						jobTitle: element.position,
						userSince: element.formattedCreatedAt,
						martialStatus: element.martialStatus,
						dateOfBirth:
							(element.dateOfBirth &&
								new Date(Number(element.dateOfBirth) / 1000).toLocaleDateString(
									'en-US',
								)) ||
							element.dateOfBirth,
						education: element.education,
						companyName: element.companyName,
						gender: element.gender,
						nationality: element.nationality,
						phoneNumber: element.phoneNumber,
					};
				}),
			);
			setCount(5);
			setCurrentPage(0);
			setLoading(false);
		} else {
			loadData('', defaultPageSize, 0);
		}
	}, []);

	return (
		<>
			<h1>Users</h1>
			<Table
				columns={columns}
				rows={data}
				pagination={true}
				paginationMode="server"
				pageSize={defaultPageSize}
				rowCount={count}
				page={currentPage}
				onPageChange={(newPage) => loadData(keyword, defaultPageSize, newPage)}
				onFilterModelChange={onFilterModelChange}
				handleOnRowClick={onRowClick}
				loading={loading}
				handleChangeRowsPerPage={(e) => {
					setDefaultPageSize(parseInt(e, 10))
					loadData(keyword, parseInt(e, 10), 0);
				}}
			/>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				fullWidth={true}
				maxWidth="lg"
			>
				<DialogContent sx={{ padding: 0, overflowY: 'hidden' }}>
					<User userId={selectedUserId}></User>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default UsersTable;
