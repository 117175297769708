import axios, { METHODS } from '../../utilities/axios';
import {
	GET_MAP_COORDINATES,
	GET_ALL_REGIONS,
	GET_ALL_COUNTRIES,
	GET_ALL_CITIES,
	POLYGON,
	CITY
} from '../../constants/endpoints.api';
import { toast } from 'react-toastify';

export const fetchMapData = () => {
	return axios(
		METHODS.GET,
		GET_MAP_COORDINATES,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
	)
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
};

export const fetchRegions = (pageSize, pageNumber, keyword) => {
	return axios(
		METHODS.GET,
		GET_ALL_REGIONS,
		undefined,
		{
			pageSize,
			pageNumber,
			keyword
		},
		undefined,
		undefined,
		undefined,
	)
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
}

export const fetchCountries = (pageSize, pageNumber, keyword) => {
	return axios(
		METHODS.GET,
		GET_ALL_COUNTRIES,
		undefined,
		{
			pageSize,
			pageNumber,
			keyword
		},
		undefined,
		undefined,
		undefined,
	)
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
}

export const fetchCities = (pageSize, pageNumber, keyword) => {
	return axios(
		METHODS.GET,
		GET_ALL_CITIES,
		undefined,
		{
			pageSize,
			pageNumber,
			keyword
		},
		undefined,
		undefined,
		undefined,
	)
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
}

export const addPolygon = (uuid, coordinates) => {
	return axios(
		METHODS.POST,
		POLYGON,
		{
			uuid,
			coordinates
		},
		undefined,
		undefined,
		undefined,
		undefined,
	)
		.then(async (response) => {
			return true;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
}

export const addCity = (uuid, name, description) => {
	return axios(
		METHODS.POST,
		CITY,
		{
			uuid,
			name,
			description
		},
		undefined,
		undefined,
		undefined,
		undefined,
	)
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
}