import { createSlice } from "@reduxjs/toolkit";

const sideBarSlice = createSlice({
    name: "sidebar",
    initialState: {
        value: {
            selected: 0,
            expanded: false
        }
    },
    reducers: {
        changeSideBar: (state, action) => {
            state.value = {...state.value, selected: action.payload.selected}
        },
        setExpanded: (state, action) => {
            state.value = {...state.value, expanded: action.payload.expanded}
        },
    },
});

export const {changeSideBar} = sideBarSlice.actions;
export const {setExpanded} = sideBarSlice.actions;

export default sideBarSlice.reducer;