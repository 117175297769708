//BASE
export const BASE_ENDPOINT = `${process.env.REACT_APP_BASE_ENDPOINT}/api`;

//AUTH
export const LOGIN_ENDPOINT = '/auth/login';
export const LOGOUT_ENDPOINT = '/auth/logout';

//USERS
export const GET_USERS_ENDPOINT = '/dashboard/users';
export const GET_USER_PROFILE_ENDPOINT = '/dashboard/user';
export const DEACTIVATE_USER = '/dashboard/user/deactive';
export const ACTIVATE_USER = '/dashboard/user/active';
export const ESCELATE_PRIVILAGE = '/dashboard/privilege/escalate';
export const DECREASE_PRIVILAGE = '/dashboard/privilege/decrease';

//FEED
export const GET_ALL_POSTS = '/post/all';
export const DELETE_POSTS = '/dashboard/post';

//STATS
export const GET_STATS = '/dashboard/stats';

//FEEDBACK
export const GET_ALL_FEEDBACK = '/user/feedback/all';
export const GET_FEEDBACK_STATS = '/dashboard/feedback';
export const REPLY_FEEDBACK = '/user/feedback';

//DATA
export const GET_HOBBIES = '/dashboard/hobbie';
export const GET_TAGS = '/tag';
export const GET_LANGUAGES = '/dashboard/language';
export const ADD_HOBBY = '/dashboard/hobbie';
export const ADD_TAG = '/tag';
export const ADD_LANGUAGE = '/dashboard/language';
export const DELETE_HOBBY = '/dashboard/hobbie';
export const DELETE_TAG = '/tag';
export const DELETE_LANGUAGE = '/dashboard/language';
export const UPDATE_TAG = '/dashboard/tag';
export const UPDATE_LANGUAGE = '/dashboard/tag';
export const UPDATE_HOBBY = '/dashboard/hobbie';

//SERVICE
export const GET_SERVICES = '/service';
export const DELETE_SERVICE = '/service';
export const ADD_SERVICE = '/service';
export const UPDATE_SERVICE = '/service';
export const UPLOAD_SERVICE_IMAGE = '/service/serviceImage';
export const GET_ALL_SUBSERVICES = '/service/sub/all';

//COMPANY
export const GET_SERVICE_COMPANIES = '/company';
export const ADD_COMPANY = '/company';
export const UPDATE_COMPANY = '/company';
export const DELETE_COMPANY = '/company';
export const UPLOAD_COMPANY_IMAGE = '/company/companyImage';
export const ADD_COMPANY_SERVICE = '/company/service';
export const ADD_COMPANY_TAGS = '/company/tag';
export const GET_ALL_COMPANIES = '/company/all';

//MAP
export const GET_MAP_COORDINATES = '/dashboard/polygons';
export const UPDATE_COUNTRY = '/country';
export const DELETE_COUNTRY = '/country';
export const DELETE_REGION = '/region';
export const UPDATE_REGION = '/region';
export const ADD_REGION = '/region';
export const ADD_COUNTRY = '/country';
export const GET_ALL_REGIONS = '/region/all';
export const GET_ALL_COUNTRIES = '/country/all';
export const GET_ALL_CITIES = '/city/all';
export const POLYGON = '/polygon';
export const CITY = '/city';
