import React from 'react';
import './Main.css';
import { Routes, Route } from 'react-router-dom';
import Analytics from '../Analytics/Analytics';
import UsersTable from '../Users/usersTable';
import Feed from '../Feed/Feed';
import Services from '../Services/Services';
import Map from '../Map/Map';
import CompaniesTable from '../Companies/companiesTable';
import FeedbackList from '../Feedback/FeedbackList';
import HobbiesTable from '../Hobbies/Hobbies';
import LanguagesTable from '../Languages/Languages';
import TagsTable from '../Tags/Tags';
import CountriesTable from '../Countries/Countries';
import RegionsTable from '../Regions/Regions';

const Main = () => {
	return (
		<div className="Main">
			<Routes>
				<Route exact path="/" element={<Analytics />} />
				<Route exact path="/feed" element={<Feed />} />
				<Route exact path="/user" element={<UsersTable />} />
				<Route exact path="/services" element={<Services />} />
				<Route exact path="/map" element={<Map />} />
				<Route exact path="/companies" element={<CompaniesTable />} />
				<Route exact path="/feedback" element={<FeedbackList/>} />
				<Route exact path="/hobbies" element={<HobbiesTable />} />
				<Route exact path="/language" element={<LanguagesTable />} />
				<Route exact path="/tags" element={<TagsTable/>} />
				<Route exact path="/region" element={<RegionsTable/>} />
				<Route exact path="/countries" element={<CountriesTable/>} />
			</Routes>
		</div>
	);
};

export default Main;
