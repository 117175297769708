import * as React from 'react';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarQuickFilter,
	GridCellEditStopReasons 
} from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import ConfirmDialog from '../Shared/ConfirmDialog';

function CustomToolbar(props) {
	return (
		<GridToolbarContainer {...props}>
			<GridToolbarColumnsButton />
			<GridToolbarQuickFilter />
		</GridToolbarContainer>
	);
}

export default function DataTable({
	columns,
	rows,
	paginationMode,
	pageSize,
	rowCount,
	onPageChange,
	pagination,
	page,
	onFilterModelChange,
	handleOnRowClick,
	loading,
	handleDelete,
	handleEdit,
	handleChangeRowsPerPage,
	handleEditCell,
}) {
	const [selectedRow, setSelectedRow] = React.useState(null);
	const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);

	if (
		!columns.find((obj) => obj.field == 'actions') &&
		(handleEdit || handleDelete)
	) {
		columns.push({
			field: 'actions',
			headerName: 'Actions',
			width: 100,
			renderCell: (params) => {
				const onEditClick = (e) => {
					e.stopPropagation(); // don't select this row after clicking
					handleEdit(params.row);
				};

				const onDeleteClick = (e) => {
					setSelectedRow(params.row);
					setDeleteConfirmOpen(true);
				};

				return (
					<>
						{handleEdit && (
							<IconButton onClick={onEditClick} color="inherit">
								<Edit />
							</IconButton>
						)}
						{handleDelete && (
							<IconButton onClick={onDeleteClick} color="inherit">
								<Delete />
							</IconButton>
						)}
					</>
				);
			},
		});
	}

	return (
		<>
			<div
				style={{
					marginTop: 20,
					height: '100%',
					width: '100%',
					paddingBottom: '32px',
				}}
			>
				<DataGrid
					rows={rows}
					columns={columns}
					pageSize={pageSize}
					paginationMode={paginationMode}
					rowCount={rowCount}
					pagination={pagination}
					page={page}
					onRowClick={handleOnRowClick}
					onPageChange={(newPage) => onPageChange(newPage)}
					components={{
						Toolbar: CustomToolbar,
					}}
					disableColumnFilter
					disableColumnSelector
					disableDensitySelector
					disableToolbarButton
					componentsProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
					filterMode="server"
					onFilterModelChange={onFilterModelChange}
					loading={loading}
					rowsPerPageOptions={[5, 10, 15, 25]}
					rowsPerPage={pageSize}
					onPageSizeChange={(e) => {
						handleChangeRowsPerPage(e)
					}}
					onCellEditStop={(params, event) => {
						if (params.reason === GridCellEditStopReasons.cellFocusOut) {
						  event.defaultMuiPrevented = true;
						}
						if (handleEditCell) {
							handleEditCell(params.row);
						}
					  }}			  
				/>
			</div>
			{selectedRow && (
				<ConfirmDialog
					title="Confirm Delete"
					open={deleteConfirmOpen}
					setOpen={setDeleteConfirmOpen}
					onConfirm={() => handleDelete(selectedRow.id)}
				>
					{`Are you sure you want to delete (${selectedRow.name}) ?`}
				</ConfirmDialog>
			)}
		</>
	);
}
