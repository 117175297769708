import { CircularProgress, Container, Grid } from '@mui/material';

const Loader = ({ size }) => {
	return (
		<Grid
			style={{
				background: 'rgb(0 0 0 / 11%)',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				position: 'absolute',
				width: '100%',
			}}
		>
			<CircularProgress
				size={size ?? 125}
				style={{
					position: 'absolute',
					top: `calc(50% - ${(size ?? 125) / 2}px)`,
					left: `calc(50% - ${(size ?? 125) / 2}px)`,
				}}
			/>
		</Grid>
	);
};

export default Loader;
