import React, { useState } from 'react';
import './Card.css';
import 'react-circular-progressbar/dist/styles.css';
import { motion, AnimateSharedLayout } from 'framer-motion';
import { UilTimes } from '@iconscout/react-unicons';
import Chart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import { changeSideBar } from '../../../store/sidebar';
import { useDispatch } from "react-redux";
import { SidebarData } from "../../../Data/Data";

// parent Card

const Card = (props) => {
	const [expanded, setExpanded] = useState(false);
	return (
		<AnimateSharedLayout>
			{expanded ? (
				<ExpandedCard param={props} setExpanded={() => setExpanded(false)} />
			) : (
				<CompactCard param={props} setExpanded={() => setExpanded(true)} />
			)}
		</AnimateSharedLayout>
	);
};

// Compact Card
function CompactCard({ param, setExpanded }) {
	
	const Png = param.png;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	const handleClick = () => {
		if (param.series) {
			setExpanded(true);
		} else {
			
			let index = SidebarData.findIndex(obj => {
				return obj.nav === param.route;
			});

			dispatch(changeSideBar({selected: index && index > -1 ? index : 0}));
			navigate(index && index > -1 ? param.route : SidebarData[0].nav);
		}
	}

	return (
		<motion.div
			className="CompactCard"
			style={{
				background: param.color.backGround,
				boxShadow: param.color.boxShadow,
			}}
			layoutId="expandableCard"
			onClick={() => {
				handleClick();
			}}
		>
			<div className="radialBar">
				<span>{param.title}</span>
			</div>
			<div className="detail">
				<Png />
				<span>{param.value}</span>
			</div>
		</motion.div>
	);
}

// Expanded Card
function ExpandedCard({ param, setExpanded }) {
	const data = {
		options: {
			chart: {
				type: 'area',
				height: 'auto',
			},

			dropShadow: {
				enabled: false,
				enabledOnSeries: undefined,
				top: 0,
				left: 0,
				blur: 3,
				color: '#000',
				opacity: 0.35,
			},

			fill: {
				colors: ['#fff'],
				type: 'gradient',
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'smooth',
				colors: ['white'],
			},
			tooltip: {
				x: {
					format: 'dd/MM/yy HH:mm',
				},
			},
			grid: {
				show: true,
			},
			xaxis: {
				type: 'datetime',
				categories: param.seriesDates,
			},
		},
	};

	const navigate = useNavigate();
	const dispatch = useDispatch();
	

	const handleExClick = () => {
		let index = SidebarData.findIndex(obj => {
			return obj.nav === param.route;
		});

		dispatch(changeSideBar({selected: index && index > -1 ? index : 0}));
		navigate(index && index > -1 ? param.route : SidebarData[0].nav);
	}

	return (
		<motion.div
			className="ExpandedCard"
			style={{
				background: param.color.backGround,
				boxShadow: param.color.boxShadow,
			}}
			layoutId="expandableCard"
		>
			<div style={{ alignSelf: 'flex-end', cursor: 'pointer', color: 'white' }}>
				<UilTimes onClick={setExpanded} />
			</div>
			<span className="span" onClick={()=>handleExClick()}>{param.title}</span>
			<div className="chartContainer">
				<Chart options={data.options} series={param.series} type="area" />
			</div>
		</motion.div>
	);
}

export default Card;
