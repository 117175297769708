import axios, { METHODS } from '../../utilities/axios';
import { LOGIN_ENDPOINT } from '../../constants/endpoints.api';
import { Cookies } from 'react-cookie';
import { toast } from 'react-toastify';
import history from '../../utilities/history';


const cookies = new Cookies();

export const handleSubmit = (values, formikApi) => {
	const { email, password } = values;
	axios(
		METHODS.POST,
		LOGIN_ENDPOINT,
		{},
		undefined,
		undefined,
		{
			username: email,
			password: password,
		},
		undefined,
	)
		.then(async (response) => {
			const accessToken = response.data.data.accessToken;
			const refreshToken = response.data.data.refreshToken;
			cookies.set('accessToken', accessToken, { path: '/', sameSite: 'strict' });
			cookies.set('refreshToken', refreshToken, { path: '/', sameSite: 'strict' });
			toast.success(
				'You\'ve logged in successfully.',
			);
			history.push('/');
		})
		.catch((error) => {
			if (error.code === 401)
            toast.error(
					'Please check your credentials.',
				);
			else if (error.code === 0)
            toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
};

