import React from 'react';
import Grid from '@mui/material/Grid';
import './user.css';
import {
	Typography,
	Chip,
	Stack,
	Box,
	Tab,
} from '@mui/material';
import Media from '../Shared/Media/media';
import AvatarGetFile from '../Shared/Media/AvatarGetFile';
import { fetchUserProfile } from './usersService';
import Loader from '../Shared/Loading';
import { TabContext, TabList, TabPanel } from '@mui/lab';

const User = ({ userId }) => {
	const [userData, setUserData] = React.useState(null);
	const [loading, setLoading] = React.useState(true);
	const [tab, setTab] = React.useState('1');

	const handleTabChange = (event, newValue) => {
		setTab(newValue);
	};

	React.useEffect(() => {
		if (userId === -1) {
			return;
		}

		fetchUserProfile(userId)
			.then((data) => {
				setUserData(data);
				setLoading(false);
			})
			.catch(() => {});
	}, [userId]);

	let tabs = {};
	let idx = 1;

	if (userData) {
		if (userData.languages !== null && userData.languages.length > 0) {
			tabs['languages'] = idx;
			idx++;
		}

		if (userData.hobbies !== null && userData.hobbies.length > 0) {
			tabs['hobbies'] = idx;
			idx++;
		}

		if (userData.activities !== null && userData.activities.length > 0) {
			tabs['activities'] = idx;
			idx++;
		}

		if (userData.history !== null && userData.history.length > 0) {
			tabs['history'] = idx;
			idx++;
		}

		if (userData.views !== null && userData.views.length > 0) {
			tabs['views'] = idx;
			idx++;
		}
	}

	const changeUser =  (item) => {
		if (item.user && item.user.uuid) { 
			setLoading(true);
			fetchUserProfile(item.user.uuid)
				.then((data) => {
					setUserData(data);
					setLoading(false);
				})
				.catch(() => {});
		} else if (item.viewer) {
			setLoading(true);
			fetchUserProfile(item.viewer)
				.then((data) => {
					setUserData(data);
					setLoading(false);
				})
				.catch(() => {});
		}
	}

	return (
		<Stack direction="column" sx={{minHeight: '400px'}}>
			{loading ? (
				<Loader />
			) : (
				<>
					<Media
						src={userData.cover.url}
						mimeType={
							userData.cover.mime[0] === '.'
								? userData.cover.mime.substring(1)
								: userData.cover.mime
						}
						isimage={userData.cover.isImage}
						className="banner"
						background={true}
					/>
					<div
						className="banner"
						style={{
							background: 'rgb(0 0 0 / 25%)',
						}}
					></div>
					<Stack
						direction="row"
						alignSelf="center"
						style={{
							zIndex: 2,
							height: '300px',
							alignItems: 'center',
							alignSelf: 'normal',
							padding: '2rem',
						}}
						spacing={4}
					>
						<Media
							className="avatar"
							src={userData.userScheme.image.url}
							mimeType={
								userData.userScheme.image.mime[0] === '.'
									? userData.userScheme.image.mime.substring(1)
									: userData.userScheme.image.mime
							}
							isimage={userData.userScheme.image.isImage}
						/>
						<Grid item spacing={2}>
							<Grid item alignSelf="center" sx={{ marginTop: '1rem' }}>
								<Typography variant="h5" textAlign="center">
									{userData.userScheme.fullName}
								</Typography>
							</Grid>
							<Grid item alignSelf="center">
								<Typography variant="subtitle1" color="orange" textAlign="center">
									{userData.userScheme.position}
								</Typography>
							</Grid>
						</Grid>
					</Stack>
					<Grid
						item
						justifyContent="center"
						direction="column"
						style={{
							paddingBottom: '32px',
							paddingLeft: '72px',
							paddingRight: '72px',
						}}
					>
						<Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
							About
						</Typography>
						<Typography wrap="nowrap" variant="body1">
							{userData.about}
						</Typography>
					</Grid>
					<Grid>
						<TabContext value={tab}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<TabList
									onChange={handleTabChange}
									aria-label="lab API tabs example"
								>
									{tabs['languages'] && (
										<Tab label="Languages" value={`${tabs['languages']}`} />
									)}
									{tabs['hobbies'] && (
										<Tab label="Hobbies" value={`${tabs['hobbies']}`} />
									)}
									{tabs['activities'] && (
										<Tab label="Activities" value={`${tabs['activities']}`} />
									)}
									{tabs['history'] && (
										<Tab label="History" value={`${tabs['history']}`} />
									)}
									{tabs['views'] && (
										<Tab label="Views" value={`${tabs['views']}`} />
									)}
								</TabList>
							</Box>
							{tabs['languages'] && (
								<TabPanel
									value={`${tabs['languages']}`}
									style={{ height: '26rem', overflowY: 'scroll' }}
								>
									<Stack direction="row" spacing={1}>
										{userData.languages.map((language) => (
											<Chip key={language.symbol} label={language.symbol} color="primary" />
										))}
									</Stack>
								</TabPanel>
							)}
							{tabs['hobbies'] && (
								<TabPanel
									value={`${tabs['hobbies']}`}
									style={{ height: '26rem', overflowY: 'scroll' }}
								>
									<Stack direction="row" spacing={1}>
										{userData.hobbies.map((hobby) => (
											<Chip key={hobby.symbol} label={hobby.symbol} color="primary" />
										))}
									</Stack>
								</TabPanel>
							)}
							{tabs['activities'] && (
								<TabPanel
									value={`${tabs['activities']}`}
									style={{ height: '18rem', overflowY: 'scroll' }}
								>
									<Stack direction="column" spacing={2}>
										{userData.activities.map((activity) => (
											<Grid container wrap="nowrap">
												<Typography
													variant="subtitle2"
													gutterBottom
													dangerouslySetInnerHTML={{
														__html: activity.text,
													}}
												></Typography>
												<Typography variant="caption" sx={{ fontWeight: 600 }}>
													{activity.formatedDate}
												</Typography>
											</Grid>
										))}
									</Stack>
								</TabPanel>
							)}
							{tabs['history'] && (
								<TabPanel
									value={`${tabs['history']}`}
									style={{ height: '18rem', overflowY: 'scroll' }}
								>
									<Stack direction="column" spacing={1}>
										{userData.history.map((item) => (
											<Grid
												onClick={()=>changeUser(item)}
												container
												wrap="nowrap"
												spacing={2}
												style={{ marginLeft: 0 }}
											>
												<Grid item alignSelf="center">
													<AvatarGetFile
														src={item.user.image.url}
														mimeType={
															item.user.image.mime[0] === '.'
																? item.user.image.mime.substring(1)
																: item.user.image.mime
														}
														isimage={item.user.image.isImage}
													/>
												</Grid>
												<Grid item xs zeroMinWidth>
													<Typography
														variant="subtitle1"
														noWrap
														sx={{ fontWeight: 600 }}
													>
														{item.user.name}
													</Typography>
													<Typography variant="caption" noWrap>
														{item.user.jobTitle}
													</Typography>
												</Grid>
											</Grid>
										))}
									</Stack>
								</TabPanel>
							)}
							{tabs['views'] && (
								<TabPanel
									value={`${tabs['views']}`}
									style={{ height: '18rem', overflowY: 'scroll' }}
								>
									<Stack direction="column" spacing={2}>
										{userData.views.map((view) => (
											<Grid
												onClick={()=>changeUser(view)}
												container
												wrap="nowrap"
												spacing={2}
												style={{ marginLeft: 0 }}
											>
												<Grid item alignSelf="center">
													<AvatarGetFile
														src={view.image.url}
														mimeType={
															view.image.mime[0] === '.'
																? view.image.mime.substring(1)
																: view.image.mime
														}
														isimage={view.image.isImage}
													/>
												</Grid>
												<Grid item xs zeroMinWidth>
													<Typography
														variant="subtitle1"
														noWrap
														sx={{ fontWeight: 600 }}
													>
														{view.name}
													</Typography>
													<Typography variant="caption" noWrap>
														{view.jobTitle}
													</Typography>
												</Grid>
											</Grid>
										))}
									</Stack>
								</TabPanel>
							)}
						</TabContext>
					</Grid>
				</>
			)}
		</Stack>
	);
};

export default User;
