import { createSlice } from '@reduxjs/toolkit';

const companiesTableSlice = createSlice({
	name: 'companies',
	initialState: {
		companies: [],
		loading: true,
		nextPage: 0,
		currentPage: 0,
		count: 0,
		keyword: '',
		companyFormOpen: false,
		companyToEdit: null,
	},
	reducers: {
		setCompanies: (state, action) => {
			state.companies = action.payload;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setNextPage: (state, action) => {
			state.nextPage = action.payload;
		},
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload;
		},
		setCount: (state, action) => {
			state.count = action.payload;
		},
		setKeyword: (state, action) => {
			state.keyword = action.payload;
		},
		setCompanyFormOpen: (state, action) => {
			state.companyFormOpen = action.payload;
		},
		setCompanyToEdit: (state, action) => {
			state.companyToEdit = action.payload;
		},
	},
});

export const {
	setCompanies,
	setLoading,
	setNextPage,
	setCurrentPage,
	setCount,
	setKeyword,
	setCompanyFormOpen,
	setCompanyToEdit,
} = companiesTableSlice.actions;

export default companiesTableSlice.reducer;
