import axios, { METHODS } from '../../utilities/axios';
import {
	ADD_HOBBY,
	ADD_LANGUAGE,
	ADD_TAG,
	DELETE_HOBBY,
	DELETE_LANGUAGE,
	DELETE_TAG,
	GET_HOBBIES,
	GET_LANGUAGES,
	GET_TAGS,
	UPDATE_HOBBY,
	UPDATE_LANGUAGE,
	UPDATE_TAG,
	GET_ALL_REGIONS,
	GET_ALL_COUNTRIES,
	UPDATE_COUNTRY,
	UPDATE_REGION,
	DELETE_COUNTRY,
	DELETE_REGION,
	ADD_REGION,
	ADD_COUNTRY
} from '../../constants/endpoints.api';

export const DataType = {
	HOBBIES: 0,
	LANGUAGES: 1,
	TAGS: 2,
	REGIONS: 3,
	COUNTRIES: 4,
};

export const fetchData = (dataType, keyword, pageSize, pageNumber) => {
	let endpoint = GET_HOBBIES;
	if (dataType === DataType.LANGUAGES) {
		endpoint = GET_LANGUAGES;
	} else if (dataType === DataType.TAGS) {
		endpoint = GET_TAGS;
	} else if (dataType === DataType.REGIONS) {
		endpoint = GET_ALL_REGIONS;
	} else if (dataType === DataType.COUNTRIES) {
		endpoint = GET_ALL_COUNTRIES;
	}

	return axios(METHODS.GET, endpoint, undefined, {
		keyword,
		pageSize,
		pageNumber,
	})
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				return Promise.reject(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) return Promise.reject(error.error);
			else return Promise.reject('Something went wrong.');
		});
};

export const addRegions = (type, name, description, uuid) => {
	let endpoint = ADD_REGION;
	let body = {
		name,
		description
	}
	if (type === DataType.COUNTRIES) {
		endpoint = ADD_COUNTRY;
		body = {
			name,
			description,
			uuid
		}
	}

	return axios(METHODS.POST, endpoint, body)
	.then(async (response) => {
		return response.data.data;
	})
	.catch((error) => {
		if (error.code === 0)
			return Promise.reject(
				'Something went wrong. Make sure you are connected to the internet.',
			);
		else if (error.error) return Promise.reject(error.error);
		else return Promise.reject('Something went wrong.');
	});

} 
export const addData = (dataType, symbol) => {
	let endpoint = ADD_HOBBY;
	if (dataType === DataType.LANGUAGES) {
		endpoint = ADD_LANGUAGE;
	} else if (dataType === DataType.TAGS) {
		endpoint = ADD_TAG;
	}

	return axios(METHODS.POST, endpoint, { symbol })
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				return Promise.reject(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) return Promise.reject(error.error);
			else return Promise.reject('Something went wrong.');
		});
};

export const deleteData = (dataType, uuid) => {
	let endpoint = DELETE_HOBBY;
	if (dataType === DataType.LANGUAGES) {
		endpoint = DELETE_LANGUAGE;
	} else if (dataType === DataType.TAGS) {
		endpoint = DELETE_TAG;
	} else if (dataType === DataType.COUNTRIES) {
		endpoint = DELETE_COUNTRY;
	} else if (dataType === DataType.REGIONS) {
		endpoint = DELETE_REGION;
	}

	return axios(METHODS.DELETE, endpoint, { uuid })
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0) {
				return Promise.reject(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			} else if (error.error) {
				return Promise.reject(error.error);
			} else {
				return Promise.reject('Something went wrong.');
			}
		});
};

export const updateData = (dataType, uuid, name, description) => {
	let endpoint = UPDATE_HOBBY;
	if (dataType === DataType.LANGUAGES) {
		endpoint = UPDATE_LANGUAGE;
	} else if (dataType === DataType.TAGS) {
		endpoint = UPDATE_TAG;
	} else if (dataType === DataType.COUNTRIES) {
		endpoint = UPDATE_COUNTRY;
	} else if (dataType === DataType.REGIONS) {
		endpoint = UPDATE_REGION;
	}

	return axios(METHODS.PUT, endpoint, { uuid, name, description })
		.then(async (response) => {
			return true
		})
		.catch((error) => {
			if (error.code === 0) {
				return Promise.reject(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			} else if (error.error) {
				return Promise.reject(error.error);
			} else {
				return Promise.reject('Something went wrong.');
			}
		});
};