// Sidebar imports
import {
  UilEstate,
  UilUsersAlt,
  UilPackage,
  UilChart,
  UilMap,
  UilBox,
  UilFeedback,
  UilFootball,
  UilLanguage,
  UilPricetagAlt,
  UilGlobe
} from "@iconscout/react-unicons";

// Sidebar Data
export const SidebarData = [
  {
    icon: UilChart,
    heading: 'Dashboard',
    nav: '/',
  },
  {
    icon: UilEstate,
    heading: "Feeds",
    nav: '/feed',
  },
  {
    icon: UilUsersAlt,
    heading: "Users",
    nav: '/user',
  },
  {
    icon: UilPackage,
    heading: 'Services',
    nav: '/services',
  },
  {
    icon: UilMap,
    heading: 'Map',
    nav: '/map',
  },
  {
    icon: UilBox,
    heading: 'Companies',
    nav: '/companies',
  },
  {
    icon: UilFeedback,
    heading: 'Feedbacks',
    nav: '/feedback'
  },
  {
    icon: UilFootball,
    heading: 'Hobbies',
    nav: '/hobbies'
  },
  {
    icon: UilGlobe,
    heading: 'Regions',
    nav: '/region'
  },
  {
    icon: UilLanguage,
    heading: 'Languages',
    nav: '/language'
  },
  {
    icon: UilPricetagAlt,
    heading: 'Tags',
    nav: '/tags'
  },
  {
    icon: UilGlobe,
    heading: 'Countries',
    nav: '/countries'
  }
];
