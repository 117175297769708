import React from 'react';
import Table from '../Table/Table';

import {
	Reply
} from '@mui/icons-material';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	IconButton,
	Stack,
	useMediaQuery
} from '@mui/material';


import {
	fetchFeedback, replyFeedback
} from './feedbackService';


const FeedbackTable = ({ feedbacks }) => {

	const [open, setOpen] = React.useState(false);
	const [replyMessage, setReplyMessage] = React.useState('');
	const [selectedFeedback, setSelectedFeedback] = React.useState({});

	const setRow = (row) => {
		setSelectedFeedback(row);
	}

	const handleReplyChange = (event) => {
		setReplyMessage(event.target.value);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const [data, setData] = React.useState([]);
	const [currentPage, setCurrentPage] = React.useState(0);
	const [count, setCount] = React.useState(0);
	const [keyword, setKeyword] = React.useState('');
	const [loading, setLoading] = React.useState(true);
	const [defaultPageSize, setDefaultPageSize] = React.useState(10);


	const columns = [
		{ field: 'uuid', headerName: 'ID', width: 70, hide: true },
		{ field: 'feedbackOwner', headerName: 'Feedback from', width: 150 },
		{ field: 'repliedBy', headerName: 'Replied By', width: 150 },
		{ field: 'description', headerName: 'Description', width: 350 },
		{ field: 'reply', headerName: 'Reply', width: 350 },
		{ field: 'createdAt', headerName: 'Sent Since', width: 130 },
		{ field: 'repliedAt', headerName: 'Replied Since', width: 130 },
		{
			field: 'actions',
			headerName: 'Actions',
			width: 120,
			renderCell: (params) => {
				const sendReply = () => {
					replyFeedback(selectedFeedback.id, replyMessage).then(
						(response) => {
							let newData = Array.from(data);
							setData(
								newData.map((row) => {
									if (row.id == selectedFeedback.id) {
										row.canReply = false
										row.reply = replyMessage;
									}
									return row;
								}),
							);
							setReplyMessage("");
							handleClose();
						},
					);
				}
				const onChangeActiveStatus = () => {
					setOpen(true);
				};
				if (params.row.canReply)
					return ( 
						<>
							<Button
								startIcon={<Reply />}
								onClick={onChangeActiveStatus}
								color="inherit"
							>
							</Button>
							<Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
							<DialogTitle>Reply to feedback</DialogTitle>
							<DialogContent>
								<DialogContentText>{selectedFeedback.row ? selectedFeedback.row.description : ""}</DialogContentText>
								<TextField
									autoFocus
									margin="dense"
									id="reply"
									label="Reply"
									type="text"
									fullWidth
									variant="standard"
									required
									value={replyMessage}
									onChange={handleReplyChange}
								/>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleClose}>Cancel</Button>
								<Button onClick={sendReply}>Send reply</Button>
							</DialogActions>
						</Dialog>


						</>
					);
			},
		},
	];

	const loadData = React.useCallback((keyword, pageSize, page) => {
		setLoading(true);
		fetchFeedback(keyword, pageSize, page)
			.then((data) => {
				let rows = data.resp.map((element) => {
					return {
						id: element.uuid,
						feedbackOwner: element.userRow ? element.userRow.name : "",
						repliedBy: element.responsedUserRow ? element.responsedUserRow.name : "",
						description: element.description,
						reply: element.reply,
						createdAt: element.formattedCreatedAt,
						repliedAt: element.formattedRepliedAt,
						canReply: element.reply === undefined || element.reply === null ? true : false
					};
				});
				setData(rows);
				setCount(data.count);
				setCurrentPage(page);
				setLoading(false);
			})
			.catch(() => setLoading(false));
	});

	const onFilterModelChange = React.useCallback((filterModel) => {
		if (filterModel.quickFilterValues.length === 0) {
			loadData('', defaultPageSize, currentPage);
		} else {
			loadData(filterModel.quickFilterValues[0], defaultPageSize, currentPage);
		}
	});

	React.useEffect(() => {
		if (feedbacks) {
			setData(
				feedbacks.map((element) => {
					return {
						id: element.uuid,
						feedbackOwner: element.userRow ? element.userRow.name : "",
						repliedBy: element.responsedUserRow ? element.responsedUserRow.name : "",
						description: element.description,
						reply: element.reply,
						createdAt: element.formattedCreatedAt,
						repliedAt: element.formattedRepliedAt,
						canReply: element.reply === undefined || element.reply === null ? true : false
					};
				}),
			);
			setCount(5);
			setCurrentPage(0);
			setLoading(false);
		} else {
			loadData('', defaultPageSize, 0);
		}
	}, []);

	return (
		<>
			<h1>Feedbacks</h1>
			<Table
				columns={columns}
				rows={data}
				pagination={true}
				paginationMode="server"
				pageSize={defaultPageSize}
				rowCount={count}
				page={currentPage}
				onPageChange={(newPage) => loadData(keyword, defaultPageSize, newPage)}
				onFilterModelChange={onFilterModelChange}
				handleOnRowClick={setRow}
				loading={loading}
				handleChangeRowsPerPage={(e) => {
					setDefaultPageSize(parseInt(e, 10))
					loadData(keyword, parseInt(e, 10), 0);
				}}
			/>
		</>
	);
};

export default FeedbackTable;
