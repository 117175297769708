import { Avatar, Skeleton } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addImage, setSendRequest } from '../../../store/images';
import getFile from '../../../utilities/getFile';

const AvatarGetFile = ({ src, mimeType, isimage, className, style }) => {
	const image = useSelector((state) => {
		return state.images.value[src];
	});
	const added = useSelector((state) => {
		for (let i = 0 ; i < state.images.sentRequest.length; i++) {
			if (state.images.sentRequest[i] === src) {
				return true;
			}
		}
		return false;
	});

	const [loading, setLoading] = React.useState(true);
	const dispatch = useDispatch();

	React.useEffect(() => {
		setLoading(true);
		console.log(added);
		if (!image && !added) {
			dispatch(setSendRequest(src));
			getFile(src, mimeType, isimage)
				.then((res) => {
					let imgObj = {};
					imgObj[src] = res;
					dispatch(addImage(imgObj));
					setLoading(false);
				})
				.catch((err) => setLoading(false));
		} else {
			setLoading(false);
		}
	}, [src, image]);

	return loading ? (
		<Skeleton variant="circular">
			<Avatar />
		</Skeleton>
	) : (
		<Avatar className={className} style={style} src={image} loading="lazy" />
	);
};

export default AvatarGetFile;
