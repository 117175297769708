import React from 'react';
import Post from '../post/Post';
import './feed.css';
import {fetchFeed} from './feedService';
import { useSelector } from 'react-redux';

const Feed = ({ posts }) => {
	const [data, setData] = React.useState([]);
	const [nextPage, setNextPage] = React.useState(0);
	const [loading, setIsLoading] = React.useState(false);
	const deleted = useSelector((state) => state.post.posts);

	React.useEffect( () => {
		let data2 = data.map(element => {
			if (deleted.find(el => el === element.id.toString()) === undefined){
				return element; 
			}
		})
		let data3 = [];
		for (let x of data2) {
			if (x === undefined) {
				continue;
			}
			data3.push(x);
		}
		setData(data3);
	}
	, [deleted]);

	let lastPageLoaded = 0;

	const loadData = React.useCallback((pageSize, page) => {
		setIsLoading(true);
		fetchFeed(pageSize, page)
			.then((response) => {
				let posts = response.resp.map((element) => {
					return {
						id: element.uuid,
						desc: element.post,
						userId: element.user.uuid,
						date: element.formatedCreatedAt,
						like: element.likes,
						comment: element.commentCount,
						attachments: element.attachments,
						userImage: element.user.image,
						username: element.user.name,
						mentionedUsers: element.mentionedUsers,
					};
				});
				lastPageLoaded = nextPage;
				setData([...data, ...posts]);
				setNextPage(response.next);
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	});

	React.useEffect(() => {
		if (posts) {
			setData(
				posts.map((element) => {
					return {
						id: element.uuid,
						desc: element.post,
						userId: element.user.uuid,
						date: element.formatedCreatedAt,
						like: element.likes,
						comment: element.commentCount,
						attachments: element.attachments,
						userImage: element.user.image,
						username: element.user.name,
						mentionedUsers: element.mentionedUsers,
					};
				}),
			);
		} else {
			loadData(10, nextPage);
		}
	}, []);
	const wrapperRef = React.useRef();

	const onScroll = (ev) => {
		if (wrapperRef.current && !loading) {
			const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;
			if (scrollTop + clientHeight === scrollHeight) {
				if (nextPage > lastPageLoaded) {
					loadData(10, nextPage);
				}
			}
		}
	};

	return (
		<div className="feed" ref={wrapperRef} onScroll={onScroll}>
			<div className="feedWrapper">
				{data.map((p) => {
					return <Post key={p.id} post={p} />
				})}
				<Post loading />
			</div>
		</div>
	);
};

export default Feed;
