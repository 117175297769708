import { createSlice } from '@reduxjs/toolkit';

const postSlice = createSlice({
	name: 'posts',
	initialState: {
        posts: []
    },
	reducers: {
		deletePost: (state, action) => {
            state.posts.push(action.payload);
		}
	},
});

export const { deletePost } = postSlice.actions;

export default postSlice.reducer;
