import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { uploadServiceImage } from './servicesService';

export const ImageUploadForm = (props) => {
	const formik = useFormik({
		initialValues: {
			image: {},
			uuid: props.uuid,
		},
		validationSchema: Yup.object({
			image: Yup.mixed().required('Image is required.'),
		}),
		onSubmit: (values, formikHelper) => {
			uploadServiceImage(values.image, values.uuid)
				.then((response) => {
					toast.success('Image added successfully.');
				})
				.catch((err) => {
					toast.error(err);
				});
		},
	});

	return (
		<Box
			component="form"
			noValidate
			sx={{ mt: 1 }}
			justifyContent="center"
			textAlign="center"
		>
			<input hidden value={formik.values.uuid} name="uuid" id="uuid"></input>
			<Button
				variant="contained"
				component="label"
				startIcon={<PhotoCamera />}
			>
				Upload
				<input
					hidden
					accept="image/*"
					multiple
					type="file"
					onChange={(event) => {
						const files = event.target.files;
						let myFiles = Array.from(files);
						formik.setFieldValue('image', myFiles[0]);
						formik.handleSubmit();
					}}
				/>{' '}
			</Button>
		</Box>
	);
};
