import React from 'react';
import Table from '../Table/Table';
import { fetchData } from '../DataSection/dataSectionService';
import { DataType } from '../DataSection/dataSectionService';


const RegionsTable = ({ feedbacks }) => {

	const [open, setOpen] = React.useState(false);
	const [replyMessage, setReplyMessage] = React.useState('');
	const [selectedFeedback, setSelectedFeedback] = React.useState({});

	const setRow = (row) => {
		setSelectedFeedback(row);
	}

	const handleReplyChange = (event) => {
		setReplyMessage(event.target.value);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const [data, setData] = React.useState([]);
	const [currentPage, setCurrentPage] = React.useState(0);
	const [count, setCount] = React.useState(0);
	const [keyword, setKeyword] = React.useState('');
	const [loading, setLoading] = React.useState(true);
	const [defaultPageSize, setDefaultPageSize] = React.useState(10);


	const columns = [
		{ field: 'uuid', headerName: 'ID', width: 70, hide: true },
		{ field: 'name', headerName: 'Name', width: 350 },
		{ field: 'description', headerName: 'Description', width: 800 },
	];

	const loadData = React.useCallback((keyword, pageSize, page) => {
		setLoading(true);
		fetchData(DataType.REGIONS, keyword, pageSize, page)
			.then((data) => {
				let rows = data.resp.map((element) => {
					return {
                        id: element.uuid,
                        name: element.name,
                        description:element.description,
                    };
				});
				setData(rows);
				setCount(data.count);
				setCurrentPage(page);
				setLoading(false);
			})
			.catch(() => setLoading(false));
	});

	const onFilterModelChange = React.useCallback((filterModel) => {
		if (filterModel.quickFilterValues.length === 0) {
			loadData('', defaultPageSize, currentPage);
		} else {
			loadData(filterModel.quickFilterValues[0], defaultPageSize, currentPage);
		}
	});

	React.useEffect(() => {
		if (feedbacks) {
			setData(
				feedbacks.map((element) => {
					return {
                        id: element.uuid,
                        name: element.name,
                        description:element.description,
                    };
				}),
			);
			setCount(5);
			setCurrentPage(0);
			setLoading(false);
		} else {
			loadData('', defaultPageSize, 0);
		}
	}, []);

	return (
		<>
			<h1>Regions</h1>
			<Table
				columns={columns}
				rows={data}
				pagination={true}
				paginationMode="server"
				pageSize={defaultPageSize}
				rowCount={count}
				page={currentPage}
				onPageChange={(newPage) => loadData(keyword, defaultPageSize, newPage)}
				onFilterModelChange={onFilterModelChange}
				handleOnRowClick={setRow}
				loading={loading}
				handleChangeRowsPerPage={(e) => {
					setDefaultPageSize(parseInt(e, 10))
					loadData(keyword, parseInt(e, 10), 0);
				}}
			/>
		</>
	);
};

export default RegionsTable;
