import {
	Add,
	BusinessCenter,
	BusinessCenterOutlined,
	PersonOutline,
	PhotoCamera,
} from '@mui/icons-material';
import {
	Box,
	Breadcrumbs,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	IconButton,
	Link,
	useMediaQuery,
} from '@mui/material';
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import {
	setServices,
	setLoading,
	setNextPage,
	setSelectedServiceId,
	setSelectedSubServiceId,
	setServiceToManipulate,
	setDeleteModalOpen,
	setServiceFormOpen,
	setServiceImagesDialogOpen,
} from '../../store/services';
import ServiceCard from './ServiceCard';
import Loader from '../Shared/Loading';
import { fetchServices, deleteService } from './servicesService';
import ConfirmDialog from '../Shared/ConfirmDialog';
import { toast } from 'react-toastify';
import ServiceForm from './ServiceForm';
import { useTheme } from '@emotion/react';
import { ImagesList } from './ImagesList';
import { ImageUploadForm } from './ImageUploadForm';
import {
	setCompanyFormOpen,
	setCompanyToManipulate,
} from '../../store/company';
import CompanyList from '../Companies/CompanyList';

const Services = () => {
	const services = useSelector((state) => state.services.services);
	const loading = useSelector((state) => state.services.loading);
	const nextPage = useSelector((state) => state.services.nextPage);
	const deleteModalOpen = useSelector(
		(state) => state.services.deleteModalOpen,
	);
	const selectedServiceId = useSelector(
		(state) => state.services.selectedServiceId,
	);
	const selectedSubServiceId = useSelector(
		(state) => state.services.selectedSubServiceId,
	);
	const serviceToManipulate = useSelector(
		(state) => state.services.serviceToManipulate,
	);
	const serviceImagesDialogOpen = useSelector(
		(state) => state.services.serviceImagesDialogOpen,
	);
	const serviceFormOpen = useSelector(
		(state) => state.services.serviceFormOpen,
	);
	const dispatch = useDispatch();
	const theme = useTheme();

	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const loadData = React.useCallback((pageSize, page, clearData) => {
		if (page === null || page == undefined) {
			return;
		}

		dispatch(setLoading({ loading: true }));
		fetchServices('', pageSize, page, true)
			.then((response) => {
				let receivedServices = response.resp.map((element) => {
					return {
						uuid: element.uuid,
						title: element.name,
						description: element.description,
						color: element.color,
						value: element.companiesCount ?? 0,
						image: element.image,
						companiesCount: element.companiesCount,
						subServices: element.subServices.map((sub) => {
							return {
								uuid: sub.uuid,
								title: sub.name,
								description: sub.description,
								color: sub.color,
								value: sub.companiesCount ?? 0,
								image: sub.image,
								companiesCount: sub.companiesCount,
							};
						}),
					};
				});

				if (clearData) {
					dispatch(setServices(receivedServices));
				} else {
					dispatch(setServices([...services, ...receivedServices]));
				}
				dispatch(setNextPage(response.next));
				dispatch(setLoading(false));
				dispatch(setServiceToManipulate(null));
			})
			.catch(() => {
				dispatch(setLoading(false));
				dispatch(setServiceToManipulate(null));
			});
	});

	const handleDeleteService = () => {
		if (!serviceToManipulate) {
			return;
		}

		deleteService(serviceToManipulate.uuid)
			.then((response) => {
				loadData(12, 0, true);
				toast.success('Service deleted successfully.');
			})
			.catch((error) => {
				toast.error('Failed to delete service.');
			});
	};

	const setDeleteConfirmOpen = (status) => {
		dispatch(setDeleteModalOpen(status));
	};

	React.useEffect(() => {
		loadData(12, nextPage, false);

		return function cleanup() {
			dispatch(setServices([]));
			dispatch(setSelectedServiceId(null));
			dispatch(setSelectedSubServiceId(null));
			dispatch(setNextPage(0));
			dispatch(setServiceToManipulate(null));
		};
	}, []);

	let selectedService = null;
	if (selectedServiceId) {
		selectedService = services.find(
			(service) => service.uuid == selectedServiceId,
		);
	}

	let selectedSubService = null;
	if (selectedService && selectedSubServiceId) {
		selectedSubService = selectedService.subServices.find(
			(service) => service.uuid == selectedSubServiceId,
		);
	}

	return (
		<Grid container direction="column" sx={{ backgroundColor: 'transparent' }}>
			<Grid
				container
				direction="column"
				sx={{ overFlow: 'auto', paddingBottom: '32px' }}
			>
				<Breadcrumbs aria-label="breadcrumb">
					<Button onClick={() => {
						dispatch(setSelectedServiceId(null))
						dispatch(setSelectedSubServiceId(null))
					}}>
						Services
					</Button>
					{selectedService && (
						<Button onClick={() => dispatch(setSelectedSubServiceId(null))}>
							{selectedService.title}
						</Button>
					)}
					{selectedSubService && (
						<Button onClick={() => dispatch(setSelectedSubServiceId(null))}>
							{selectedSubService.title}
						</Button>
					)}
					{/* <Button aria-current="page">Company</Button> */}
					<IconButton
						onClick={() => {
							if (selectedSubServiceId) {
								dispatch(setCompanyToManipulate(null));
								dispatch(setCompanyFormOpen(true));
							} else {
								if (serviceToManipulate) {
									dispatch(setServiceToManipulate(null));
								}
								dispatch(setServiceFormOpen(true));
							}
						}}
					>
						<Add />
					</IconButton>
				</Breadcrumbs>
				{selectedSubServiceId ? (
					<CompanyList />
				) : (
					<>
						<Grid
							item
							container
							spacing={2}
							sx={{ margin: 0, paddingBottom: '32px' }}
						>
							{loading && services.length === 0 && <Loader />}
							{selectedServiceId
								? selectedService.subServices.map((card, id) => {
										return (
											<Grid item md={4} sm={6} xs={12} key={card.uuid}>
												<ServiceCard
													title={card.title}
													color={card.color}
													value={card.value}
													png={BusinessCenterOutlined}
													description={card.description}
													uuid={card.uuid}
													image={card.image}
													isSubService={true}
													onViewClick={() =>
														dispatch(setSelectedSubServiceId(card.uuid))
													}
													onDeleteClick={() => {
														dispatch(setServiceToManipulate(card));
														dispatch(setDeleteModalOpen(true));
													}}
													onEditClick={() => {
														dispatch(setServiceToManipulate(card));
														dispatch(setServiceFormOpen(true));
													}}
													onViewImagesClick={() => {
														dispatch(setServiceToManipulate(card));
														dispatch(setServiceImagesDialogOpen(true));
													}}
												/>
											</Grid>
										);
								  })
								: services.map((card, id) => {
										return (
											<Grid item md={4} sm={6} xs={12} key={card.uuid}>
												<ServiceCard
													title={card.title}
													color={card.color}
													value={card.value}
													png={BusinessCenterOutlined}
													description={card.description}
													uuid={card.uuid}
													image={card.image}
													isSubService={false}
													onViewClick={() =>
														dispatch(setSelectedServiceId(card.uuid))
													}
													onDeleteClick={() => {
														dispatch(setServiceToManipulate(card));
														dispatch(setDeleteModalOpen(true));
													}}
													onEditClick={() => {
														dispatch(setServiceToManipulate(card));
														dispatch(setServiceFormOpen(true));
													}}
													onViewImagesClick={() => {
														dispatch(setServiceToManipulate(card));
														dispatch(setServiceImagesDialogOpen(true));
													}}
												/>
											</Grid>
										);
								  })}
						</Grid>
						{serviceToManipulate && (
							<ConfirmDialog
								title="Delete Service?"
								open={deleteModalOpen}
								setOpen={setDeleteConfirmOpen}
								onConfirm={handleDeleteService}
							>
								{`Are you sure you want to delete this service (${serviceToManipulate.title}) ?`}
							</ConfirmDialog>
						)}
						{nextPage !== undefined &&
							nextPage !== null &&
							services.length > 0 && (
								<LoadingButton
									sx={{ alignSelf: 'center', marginTop: 2 }}
									onClick={() => loadData(12, nextPage, false)}
									loading={loading}
								>
									Load more
								</LoadingButton>
							)}
						<Dialog
							open={serviceFormOpen}
							onClose={() => dispatch(setServiceFormOpen(false))}
							aria-labelledby="edit service"
						>
							<DialogContent>
								<ServiceForm
									onSuccess={() => {
										dispatch(setServiceFormOpen(false));
										loadData(12, 0, true);
									}}
									uuid={selectedService?.uuid}
									{...serviceToManipulate}
									isUpdateForm={serviceToManipulate !== null}
								/>
							</DialogContent>
						</Dialog>
						<Dialog
							open={serviceImagesDialogOpen}
							onClose={() => dispatch(setServiceImagesDialogOpen(false))}
							aria-labelledby="serviceImages"
							maxWidth="md"
							fullWidth
						>
							<DialogContent>
								<ImagesList images={serviceToManipulate?.images} />
							</DialogContent>
							<DialogActions>
								<ImageUploadForm uuid={serviceToManipulate?.uuid} />
							</DialogActions>
						</Dialog>
					</>
				)}
			</Grid>
		</Grid>
	);
};

export default Services;
