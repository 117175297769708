import Cookies from 'universal-cookie';
import axios, { METHODS } from './axios';
import {Buffer} from 'buffer';

const getFile = (fileUrl, mimeType, isImage) => {
	const cookies = new Cookies();

	return axios(
		METHODS.GET,
		fileUrl,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
        'arraybuffer'
	)
		.then(async (response) => {
            return `data:${isImage ? 'image' : 'video'}/${mimeType};base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
		});
};

export default getFile;

