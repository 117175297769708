import { createSlice } from '@reduxjs/toolkit';

const servicesSlice = createSlice({
	name: 'services',
	initialState: {
		services: [],
		loading: true,
		nextPage: 0,
		deleteModalOpen: false,
		selectedServiceId: null,
		selectedSubServiceId: null,
		serviceToManipulate: null,
		serviceFormOpen: false,
		form: {
			loading: false,
		},
		serviceImagesDialogOpen: false,
	},
	reducers: {
		setServices: (state, action) => {
			state.services = action.payload;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setNextPage: (state, action) => {
			state.nextPage = action.payload;
		},
		setDeleteModalOpen: (state, action) => {
			state.deleteModalOpen = action.payload;
		},
		setSelectedServiceId: (state, action) => {
			state.selectedServiceId = action.payload;
		},
		setSelectedSubServiceId: (state, action) => {
			state.selectedSubServiceId = action.payload;
		},
		setServiceToManipulate: (state, action) => {
			state.serviceToManipulate = action.payload;
		},
		setServiceFormOpen: (state, action) => {
			state.serviceFormOpen = action.payload;
		},
		setFormLoading: (state, action) => {
			state.form.loading = action.payload;
		},
		setServiceImagesDialogOpen: (state, action) => {
			state.serviceImagesDialogOpen = action.payload;
		},
	},
});

export const {
	setServices,
	setLoading,
	setNextPage,
	setDeleteModalOpen,
	setSelectedServiceId,
	setSelectedSubServiceId,
	setServiceToManipulate,
	setServiceFormOpen,
	setFormLoading,
	setServiceImagesDialogOpen,
} = servicesSlice.actions;

export default servicesSlice.reducer;
