import '../Services/services.css';
import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	TextField,
	Box,
	Typography,
	IconButton,
	Grid,
	FormControl,
	InputLabel,
	Select,
	useTheme,
	Input,
	MenuItem,
	Stack,
	Autocomplete,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setFormLoading } from '../../store/company';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import Media from '../Shared/Media/media';
import { PhotoCamera } from '@mui/icons-material';
import {
	fetchAllSubServices,
	handleCompanyAdd,
	handleCompanyAddService,
	handleCompanyAddTag,
	handleCompanyUpdate,
	uploadCompanyImage,
} from './companyService';
import MuiPhoneNumber from 'material-ui-phone-number';
import { DataType, fetchData } from '../DataSection/dataSectionService';

const CompanyForm = (props) => {
	const {
		isUpdateForm,
		title,
		description,
		email,
		phoneNumber,
		tags,
		uuid,
		useTagsAsUUIDs,
		companyServiceUuids,
		showServicesInput,
		services,
	} = props;

	let originalTags = tags ?? [];
	let originalServices = services;

	const loading = useSelector((state) => state.companies.form.loading);
	const selectedSubServiceId = useSelector(
		(state) => state.services.selectedSubServiceId,
	);
	const [images, setImages] = React.useState(null);
	const [fetchedTags, setFetchedTags] = React.useState([]);
	const [fetchedServices, setFetchedServices] = React.useState([]);
	const dispatch = useDispatch();

	const theme = useTheme();

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const formikInitialValues = {
		name: title ?? '',
		description: description ?? '',
		email: email ?? '',
		phoneNumber: phoneNumber ?? '',
		tags: tags ?? [],
		services: services ?? [],
	};

	if (uuid) {
		formikInitialValues['uuid'] = uuid;
	}

	if (companyServiceUuids) {
		formikInitialValues['services'] = companyServiceUuids;
	}

	const formik = useFormik({
		initialValues: formikInitialValues,
		validationSchema: Yup.object({
			name: Yup.string().required('Name is required.'),
			description: Yup.string().required('Description is required.'),
			email: Yup.string()
				.email('Email is not valid.')
				.required('Email is required.'),
			phoneNumber: Yup.string()
				.matches('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$', {
					message: 'Phone number is not valid',
				})
				.required('Phonen number is required.'),
			tags: Yup.array(),
			services: Yup.array(),
		}),
		onSubmit: (values, formikHelper) => {
			dispatch(setFormLoading(true));
			if (images) {
				uploadCompanyImage(images)
					.then((response) => {
						let imageUUIDs = null;
						if (response.uploadedFiles.length > 0) {
							imageUUIDs = response.uploadedFiles;
						}

						if (isUpdateForm) {
							handleCompanyUpdate(values, imageUUIDs)
								.then((response) => {
									toast.success('Company added successfully.');
									dispatch(setFormLoading(false));
									props.onSuccess();
								})
								.catch((err) => {
									toast.error(err);
									dispatch(setFormLoading(false));
								});
						} else {
							handleCompanyAdd(values, imageUUIDs)
								.then((response) => {
									handleCompanyAddService(response.uuid, [selectedSubServiceId])
										.then((response) => {
											toast.success('Company added successfully.');
											dispatch(setFormLoading(false));
											props.onSuccess();
										})
										.catch((err) => {
											toast.error(err);
											dispatch(setFormLoading(false));
										});
									handleCompanyAddTag(response.uuid, formik.values.tags);
								})
								.catch((err) => {
									toast.error(err);
									dispatch(setFormLoading(false));
								});
						}
					})
					.catch((error) => {
						toast.error('Failed to update company.');
					});
			} else {
				if (isUpdateForm) {
					handleCompanyUpdate(values)
						.then((response) => {
							toast.success('Company added successfully.');
							dispatch(setFormLoading(false));
							props.onSuccess();
						})
						.catch((err) => {
							toast.error(err);
							dispatch(setFormLoading(false));
						});
				} else {
					handleCompanyAdd(values)
						.then((response) => {
							handleCompanyAddService(response.uuid, [selectedSubServiceId])
								.then((response) => {
									toast.success('Company added successfully.');
									dispatch(setFormLoading(false));
									props.onSuccess();
								})
								.catch((err) => {
									toast.error(err);
									dispatch(setFormLoading(false));
								});
							handleCompanyAddTag(response.uuid, formik.values.tags);
						})
						.catch((err) => {
							toast.error(err);
							dispatch(setFormLoading(false));
						});
				}
			}
			if (
				formik.values.uuid &&
				!(
					formik.values.tags.every((item) => originalTags.includes(item)) &&
					originalTags.every((item) => formik.values.tags.includes(item))
				)
			) {
				handleCompanyAddTag(formik.values.uuid, formik.values.tags);
			}

			if (formik.values.services && originalServices) {
				let oldServices = originalServices;
				let newServices = formik.values.services.map((item) => item.id);

				if (
					!(
						newServices.every((item) => oldServices.includes(item)) &&
						oldServices.every((item) => newServices.includes(item))
					)
				) {
					handleCompanyAddService(formik.values.uuid, newServices);
				}
			}
		},
	});
	
	function getStyles(name, personName, theme) {
		return {
			fontWeight:
				personName.indexOf(name) === -1
					? theme.typography.fontWeightRegular
					: theme.typography.fontWeightMedium,
		};
	}

	const loadSubServices = (keyword, pageSize, page) => {
		fetchAllSubServices(keyword, pageSize, page).then((response) => {
			setFetchedServices(response.resp);
			originalServices = [];
			for (let idx = 0; idx < response.resp.length; idx++) {
				if (props.services && props.services.includes(response.resp[idx].uuid)) {
					originalServices.push({
						id: response.resp[idx].uuid,
						label: response.resp[idx].name,
					});
				}
			}
			formik.setFieldValue('services', originalServices);
		});
	};

	React.useEffect(() => {
		fetchData(DataType.TAGS, '', 200, 0)
			.then((response) => {
				setFetchedTags(response.resp);
				if (!useTagsAsUUIDs) {
					originalTags = response.resp.map((fetchedTag) => {
						if (props.tags && props.tags.includes(fetchedTag.symbol)) {
							return fetchedTag.uuid;
						}
					});
				} else {
					originalTags = props.tags.map((recTag) => recTag.uuid);
				}

				formik.setFieldValue('tags', originalTags);
			})
			.catch();

		loadSubServices('', 200, 0);
	}, []);

	return (
		<>
			<Typography component="h1" variant="h5">
				{isUpdateForm ? 'Edit company' : 'Add company'}
			</Typography>
			<Box
				component="form"
				onSubmit={formik.handleSubmit}
				noValidate
				sx={{ mt: 1 }}
				justifyContent="center"
				textAlign="center"
			>
				<input hidden value={formik.values.uuid} name="uuid" id="uuid"></input>
				<TextField
					margin="normal"
					required
					fullWidth
					id="name"
					label="Name"
					name="name"
					autoFocus
					value={formik.values.name}
					onChange={formik.handleChange}
					error={formik.touched.name && Boolean(formik.errors.name)}
					helperText={formik.touched.name && formik.errors.name}
					sx={{ marginBottom: 1 }}
				/>
				<TextField
					margin="normal"
					required
					fullWidth
					id="description"
					label="Description"
					name="description"
					multiline
					rows={4}
					value={formik.values.description}
					onChange={formik.handleChange}
					error={
						formik.touched.description && Boolean(formik.errors.description)
					}
					helperText={formik.touched.description && formik.errors.description}
					sx={{ marginBottom: 1 }}
				/>
				<TextField
					margin="normal"
					required
					fullWidth
					id="email"
					label="Email"
					name="email"
					type="email"
					value={formik.values.email}
					onChange={formik.handleChange}
					error={formik.touched.email && Boolean(formik.errors.email)}
					helperText={formik.touched.email && formik.errors.email}
					sx={{ marginBottom: 1 }}
				/>
				<MuiPhoneNumber
					name="phoneNumber"
					value={formik.values.phoneNumber}
					onChange={(value) => formik.setFieldValue('phoneNumber', value)}
					required
					id="phoneNumber"
					defaultCountry={'ae'}
					style={{ width: '100%' }}
					label="Phone number"
					variant="outlined"
					margin="normal"
					disableAreaCodes
					error={Boolean(formik.errors.phoneNumber)}
					helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
				/>
				<FormControl sx={{ m: 1 }} fullWidth>
					<InputLabel id="tags-input-label">Tags</InputLabel>
					<Select
						labelId="tags-label"
						id="tags"
						multiple
						value={formik.values.tags}
						onChange={formik.handleChange}
						name="tags"
						input={<Input label="Tags" />}
						MenuProps={{
							PaperProps: {
								style: {
									maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
									width: 250,
								},
							},
						}}
					>
						{fetchedTags.map((tag) => (
							<MenuItem
								key={tag.uuid}
								value={tag.uuid}
								style={getStyles(tag.uuid, formik.values.tags, theme)}
							>
								{tag.symbol}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{showServicesInput && (
					<Autocomplete
						disablePortal
						multiple
						id="combo-box-demo"
						options={fetchedServices.map((serv) => ({
							label: serv.name,
							id: serv.uuid,
						}))}
						sx={{ width: 300 }}
						onChange={(event, value) => {
							formik.setFieldValue('services', value);
						}}
						value={formik.values.serv}
						renderInput={(params) => <TextField {...params} label="Services" />}
						isOptionEqualToValue={(option, value) => option.id === value.id}
					/>
				)}
				<Typography gutterBottom textAlign="left" sx={{ marginY: '1rem' }}>
					Image
				</Typography>
				<Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap">
					{images
						? Array.from(images).map((image) => (
								<img
									src={URL.createObjectURL(image)}
									style={{ width: '200px' }}
								/>
						  ))
						: props.images &&
						  props.images.map((image) => (
								<Media
									src={image.url}
									mimeType={
										image.mime[0] === '.' ? image.mime.substring(1) : image.mime
									}
									isimage={image.isImage}
									style={{ width: '200px' }}
								/>
						  ))}
					<label htmlFor="contained-button-file">
						<IconButton
							color="primary"
							aria-label="upload picture"
							component="label"
						>
							<input
								hidden
								accept="image/*"
								type="file"
								multiple
								onChange={(ev) => setImages(ev.target.files)}
							/>
							<PhotoCamera />
						</IconButton>
					</label>
				</Stack>
				<LoadingButton
					type="submit"
					variant="contained"
					sx={{ mt: 3, mb: 2 }}
					loading={loading}
				>
					Submit
				</LoadingButton>
			</Box>
		</>
	);
};

export default CompanyForm;
