import './services.css';
import * as React from 'react';
import {
	handleServiceAdd,
	handleServiceUpdate,
	uploadServiceImage,
} from './servicesService';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	FormHelperText,
	TextField,
	Box,
	Typography,
	Input,
	Button,
	IconButton,
	Grid,
} from '@mui/material';
import { SketchPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import { setFormLoading } from '../../store/services';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import Media from '../Shared/Media/media';
import { PhotoCamera } from '@mui/icons-material';

export default function ServiceForm(props) {
	const { isUpdateForm, title, description, color, uuid } = props;

	const loading = useSelector((state) => state.services.form.loading);
	const [image, setImage] = React.useState(null);

	const dispatch = useDispatch();

	const formikInitialValues = {
		name: title ?? '',
		description: description ?? '',
		color: {
			hex: color ?? '#333333',
		},
	};
	if (uuid) {
		formikInitialValues['uuid'] = uuid;
	}

	const formik = useFormik({
		initialValues: formikInitialValues,
		validationSchema: Yup.object({
			name: Yup.string().required('Name is required.'),
			description: Yup.string().required('Description is required.'),
			color: Yup.object().required('Color is required.'),
		}),
		onSubmit: (values, formikHelper) => {
			dispatch(setFormLoading(true));
			if (image) {
				uploadServiceImage(image)
					.then((response) => {
						let imageUUID = null;
						if (response.uploadedFiles.length > 0) {
							imageUUID = response.uploadedFiles[0];
						}

						if (isUpdateForm) {
							handleServiceUpdate(values, imageUUID)
								.then((response) => {
									toast.success('Service added successfully.');
									dispatch(setFormLoading(false));
									props.onSuccess();
								})
								.catch((err) => {
									toast.error(err);
									dispatch(setFormLoading(false));
								});
						} else {
							handleServiceAdd(values, imageUUID)
								.then((response) => {
									toast.success('Service added successfully.');
									dispatch(setFormLoading(false));
									props.onSuccess();
								})
								.catch((err) => {
									toast.error(err);
									dispatch(setFormLoading(false));
								});
						}
					})
					.catch((error) => {
						toast.error('Failed to update service.');
					});
			} else {
				if (isUpdateForm) {
					handleServiceUpdate(values)
						.then((response) => {
							toast.success('Service added successfully.');
							dispatch(setFormLoading(false));
							props.onSuccess();
						})
						.catch((err) => {
							toast.error(err);
							dispatch(setFormLoading(false));
						});
				} else {
					handleServiceAdd(values)
						.then((response) => {
							toast.success('Service added successfully.');
							dispatch(setFormLoading(false));
							props.onSuccess();
						})
						.catch((err) => {
							toast.error(err);
							dispatch(setFormLoading(false));
						});
				}
			}
		},
	});

	return (
		<>
			<Typography component="h1" variant="h5">
				{isUpdateForm ? 'Edit service' : 'Add service'}
			</Typography>
			<Box
				component="form"
				onSubmit={formik.handleSubmit}
				noValidate
				sx={{ mt: 1 }}
				justifyContent="center"
				textAlign="center"
			>
				<input hidden value={formik.values.uuid} name="uuid" id="uuid"></input>
				<TextField
					margin="normal"
					required
					fullWidth
					id="name"
					label="Name"
					name="name"
					autoFocus
					value={formik.values.name}
					onChange={formik.handleChange}
					error={formik.touched.name && Boolean(formik.errors.name)}
					helperText={formik.touched.name && formik.errors.name}
					sx={{ marginBottom: 1 }}
				/>
				<TextField
					margin="normal"
					required
					fullWidth
					id="description"
					label="Description"
					name="description"
					multiline
					rows={4}
					value={formik.values.description}
					onChange={formik.handleChange}
					error={
						formik.touched.description && Boolean(formik.errors.description)
					}
					helperText={formik.touched.description && formik.errors.description}
					sx={{ marginBottom: 1 }}
				/>
				<Typography gutterBottom textAlign="left" sx={{ marginY: '1rem' }}>
					Color
				</Typography>
				<SketchPicker
					color={formik.values.color}
					onChange={(color, event) => formik.setFieldValue('color', color)}
				/>
				{formik.touched.color && Boolean(formik.errors.color) && (
					<FormHelperText color="error">{formik.errors.color}</FormHelperText>
				)}
				<Typography gutterBottom textAlign="left" sx={{ marginY: '1rem' }}>
					Image
				</Typography>
				<Grid container direction="row" alignItems="center">
					{image ? (
						<img src={URL.createObjectURL(image)} style={{ width: '200px' }} />
					) : (
						props.image && (
							<Media
								src={props.image.url}
								mimeType={
									props.image.mime[0] === '.'
										? props.image.mime.substring(1)
										: props.image.mime
								}
								isimage={props.image.isImage}
								style={{ width: '200px' }}
							/>
						)
					)}
					<label htmlFor="contained-button-file">
						<IconButton
							color="primary"
							aria-label="upload picture"
							component="label"
						>
							<input
								hidden
								accept="image/*"
								type="file"
								onChange={(ev) => setImage(ev.target.files[0])}
							/>
							<PhotoCamera />
						</IconButton>
					</label>
				</Grid>
				<LoadingButton
					type="submit"
					variant="contained"
					sx={{ mt: 3, mb: 2 }}
					loading={loading}
				>
					Submit
				</LoadingButton>
			</Box>
		</>
	);
}
