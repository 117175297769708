import axios, { METHODS } from '../../utilities/axios';
import {
	GET_ALL_FEEDBACK,
	GET_FEEDBACK_STATS,
	REPLY_FEEDBACK,
} from '../../constants/endpoints.api';
import { toast } from 'react-toastify';

export const fetchFeedback = (keyword ,pageSize, pageNumber) => {
	return axios(
		METHODS.GET,
		GET_ALL_FEEDBACK,
		undefined,
		{
			keyword,
			pageSize,
			pageNumber,
		},
		undefined,
		undefined,
		undefined,
	)
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
};

export const fetchFeedbackStats = () => {
	return axios(METHODS.GET, GET_FEEDBACK_STATS)
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
};

export const replyFeedback = (uuid, reply) => {
	return axios(METHODS.PUT, REPLY_FEEDBACK, {
		uuid,
		reply,
	})
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
};
