import {
	Add,
	BusinessCenter,
	BusinessCenterOutlined,
	PersonOutline,
	PhotoCamera,
} from '@mui/icons-material';
import {
	Box,
	Breadcrumbs,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	IconButton,
	Link,
	useMediaQuery,
} from '@mui/material';
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Shared/Loading';
import ConfirmDialog from '../Shared/ConfirmDialog';
import { toast } from 'react-toastify';
import CompanyForm from './CompanyForm';
import { useTheme } from '@emotion/react';
import {
	setCompanies,
	setCompanyFormOpen,
	setCompanyImagesDialogOpen,
	setCompanyToManipulate,
	setFormLoading,
	setLoading,
	setNextPage,
	setDeleteModalOpen,
} from '../../store/company';
import { deleteCompany, fetchCompanies } from './companyService';
import CompanyCard from './CompanyCard';

const CompanyList = () => {
	const companies = useSelector((state) => state.companies.companies);

	const selectedSubServiceId = useSelector(
		(state) => state.services.selectedSubServiceId,
	);
	const loading = useSelector((state) => state.companies.loading);
	const nextPage = useSelector((state) => state.companies.nextPage);
	const deleteModalOpen = useSelector(
		(state) => state.companies.deleteModalOpen,
	);
	const companyToManipulate = useSelector(
		(state) => state.companies.companyToManipulate,
	);
	const companyImagesDialogOpen = useSelector(
		(state) => state.companies.companyImagesDialogOpen,
	);
	const companyFormOpen = useSelector(
		(state) => state.companies.companyFormOpen,
	);
	const dispatch = useDispatch();
	const theme = useTheme();

	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const loadData = React.useCallback((pageSize, page, clearData) => {
		if (page === null || page == undefined) {
			return;
		}
		dispatch(setLoading({ loading: true }));
		fetchCompanies(selectedSubServiceId, '', pageSize, page)
			.then((response) => {
				let receivedCompanies = response.resp.map((element) => {
					return {
						uuid: element.company.uuid,
						title: element.company.name,
						description: element.company.description,
						email: element.company.email,
						phoneNumber: element.company.phoneNumber,
						images: element.company.images,
						tags: element.company.tags,
						serviceRate: element.serviceRate ?? 0,
						userRate: element.userRate ?? 0,
					};
				});
				if (clearData) {
					dispatch(setCompanies(receivedCompanies));
				} else {
					dispatch(setCompanies([...companies, ...receivedCompanies]));
				}
				dispatch(setNextPage(response.next));
				dispatch(setFormLoading(false));
				dispatch(setCompanyToManipulate(null));
				dispatch(setLoading(false));
			})
			.catch(() => {
				dispatch(setLoading(false));
				dispatch(setCompanyToManipulate(null));
			});
	});

	const handleDeleteCompany = () => {
		if (!companyToManipulate) {
			return;
		}

		deleteCompany(companyToManipulate.uuid)
			.then((response) => {
				loadData(12, 0, true);
				toast.success('Company deleted successfully.');
			})
			.catch((error) => {
				toast.error('Failed to delete company.');
			});
	};

	const setDeleteConfirmOpen = (status) => {
		dispatch(setDeleteModalOpen(status));
	};

	React.useEffect(() => {
		loadData(12, nextPage, false);

		return function cleanup() {
			dispatch(setCompanies([]));
			dispatch(setNextPage(0));
			dispatch(setCompanyToManipulate(null));
		};
	}, []);

	return (
		<>
			<Grid
				item
				container
				spacing={2}
				sx={{ margin: 0, paddingBottom: '32px', width: '100%' }}
			>
				{loading && <Loader />}
				{companies.map((card, id) => {
					return (
						<Grid item md={4} sm={6} xs={12} key={card.uuid}>
							<CompanyCard
								title={card.title}
								email={card.email}
								phoneNumber={card.phoneNumber}
								description={card.description}
								uuid={card.uuid}
								images={card.images}
								serviceRating={card.serviceRate}
								userRating={card.userRate}
								tags={card.tags}
								// onViewClick={() => dispatch(setSelectedServiceId(card.uuid))}
								onDeleteClick={() => {
									dispatch(setCompanyToManipulate(card));
									dispatch(setDeleteModalOpen(true));
								}}
								onEditClick={() => {
									dispatch(setCompanyToManipulate(card));
									dispatch(setCompanyFormOpen(true));
								}}
								onViewImagesClick={() => {
									dispatch(setCompanyToManipulate(card));
									dispatch(setCompanyImagesDialogOpen(true));
								}}
							/>
						</Grid>
					);
				})}
			</Grid>
			{companyToManipulate && (
				<ConfirmDialog
					title="Delete Company?"
					open={deleteModalOpen}
					setOpen={setDeleteConfirmOpen}
					onConfirm={handleDeleteCompany}
				>
					{`Are you sure you want to delete this company (${companyToManipulate.title}) ?`}
				</ConfirmDialog>
			)}
			{nextPage !== undefined && nextPage !== null && companies.length > 0 && (
				<LoadingButton
					sx={{ alignSelf: 'center', marginTop: 2 }}
					onClick={() => loadData(12, nextPage, false)}
					loading={loading}
				>
					Load more
				</LoadingButton>
			)}
			<Dialog
				open={companyFormOpen}
				onClose={() => dispatch(setCompanyFormOpen(false))}
				aria-labelledby="edit company"
			>
				<DialogContent>
					<CompanyForm
						onSuccess={() => {
							dispatch(setCompanyFormOpen(false));
							loadData(12, 0, true);
						}}
						{...companyToManipulate}
						isUpdateForm={companyToManipulate !== null}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default CompanyList;
