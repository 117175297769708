import React from 'react';
import LoginView from '../components/login/login';
import { Navigate, Route, Routes } from 'react-router-dom';
import { HOMEPAGE } from '../constants/routes.api';
import { Cookies } from 'react-cookie';

const AuthRouter = () => {
  const cookies = new Cookies();
  if (cookies.get('accessToken')) return <Navigate to={HOMEPAGE} />;

  return (
    <Routes>
      <Route path='*' element={<LoginView />}></Route>
    </Routes>
  );
};

export default AuthRouter;
