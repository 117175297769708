import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import history from './utilities/history';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import themeOptions from './constants/theme';
import { socket, SocketContext } from './utilities/socket';

const theme = createTheme(themeOptions);

ReactDOM.render(
	<HistoryRouter history={history}>
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					bodyStyle={{
						borderRadius: 0,
					}}
				/>
				<App />
			</ThemeProvider>
		</Provider>
	</HistoryRouter>,
	document.getElementById('root'),
);
