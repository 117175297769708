import axios, { METHODS } from '../../utilities/axios';
import { GET_STATS } from '../../constants/endpoints.api';
import { toast } from 'react-toastify';

const fetchStats = (keyword, pageSize, pageNumber) => {
    
    return axios(
        METHODS.GET,
        GET_STATS
    )
        .then(async (response) => {
            return response.data.data;
        })
        .catch((error) => {
            if (error.code === 0)
            toast.error(
                    'Something went wrong. Make sure you are connected to the internet.',
                );
            else if (error.error) toast.error(error.error);
            else toast.error('Something went wrong.');
        });
};

export default fetchStats;