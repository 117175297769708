import React, { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import {
	Visibility,
	Edit,
	Delete,
	Collections,
	AlternateEmail,
	LocalPhone,
	ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import CardMediaGetFile from '../Shared/Media/CardMediaGetFile';
import { styled } from '@mui/material/styles';

import {
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	Chip,
	Collapse,
	Grid,
	IconButton,
	Rating,
	Stack,
	Typography,
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}));

const CompanyCard = (props) => {
	const [expanded, setExpanded] = React.useState(false);

	return (
		<Card
			sx={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}
		>
			<CardHeader title={props.title} subheader={props.formattedCreatedAt} />
			<Carousel>
				{props.images.map((image) => (
					<CardMediaGetFile
						key={image.uuid}
						component="img"
						height="200"
						src={image.url}
						mimetype={
							image.mime[0] === '.' ? image.mime.substring(1) : image.mime
						}
						isimage={true}
						alt="Paella dish"
					/>
				))}
			</Carousel>
			<CardContent>
				<Typography variant="body2" color="text.primary">
					{props.description}
				</Typography>
				<Stack
					flexDirection="row"
					justifyContent="space-between"
					marginY={0.75}
				>
					<Typography variant="body2" color="text.secondary">
						<AlternateEmail fontSize="small" /> {props.email}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						<LocalPhone fontSize="small" /> {props.phoneNumber}
					</Typography>
				</Stack>
				<Stack
					flexDirection="row"
					justifyContent="space-between"
					marginY={0.75}
				>
					<Stack flexDirection="column">
						<Typography>Servie Rating</Typography>
						<Rating name="read-only" value={props.serviceRating} readOnly />
					</Stack>
					<Stack flexDirection="column">
						<Typography>User Rating</Typography>
						<Rating name="read-only" value={props.userRating} readOnly />
					</Stack>
				</Stack>
			</CardContent>
			<CardActions disableSpacing>
				<IconButton onClick={props.onEditClick} aria-label="edit">
					<Edit color="primary" />
				</IconButton>
				<IconButton
					sx={{ marginLeft: 'auto' }}
					aria-label="delete"
					onClick={props.onDeleteClick}
				>
					<Delete color="error" />
				</IconButton>
				<ExpandMore
					expand={expanded}
					onClick={() => {
						setExpanded(!expanded);
					}}
					aria-expanded={expanded}
					aria-label="show more"
				>
					<ExpandMoreIcon />
				</ExpandMore>
			</CardActions>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<CardContent>
					<Typography paragraph>Tags:</Typography>
					<Stack direction="row" spacing={1} flexWrap='wrap'>
						{props.tags.map((tag) => (
							<Chip label={tag} variant="outlined" sx={{marginBottom: 1}}/>
						))}
					</Stack>
				</CardContent>
			</Collapse>
		</Card>
	);
};

export default CompanyCard;
