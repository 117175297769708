import React from 'react';
import Table from '../Table/Table';
import { fetchData, deleteData, updateData } from '../DataSection/dataSectionService';
import { DataType } from '../DataSection/dataSectionService';
import { toast } from 'react-toastify';

const HobbiesTable = ({ feedbacks }) => {

    const [data, setData] = React.useState([]);
	const [currentPage, setCurrentPage] = React.useState(0);
	const [count, setCount] = React.useState(0);
	const [keyword, setKeyword] = React.useState('');
	const [loading, setLoading] = React.useState(true);
	const [defaultPageSize, setDefaultPageSize] = React.useState(10);


	const columns = [
		{ field: 'uuid', headerName: 'ID', width: 70, hide: true },
		{ field: 'name', headerName: 'Name', width: 800, editable: true },
		{ field: 'precentage', headerName: 'Precentage (%)', width: 300 },
	];

    const handleDelete = (uuid) => {
        deleteData(DataType.HOBBIES, uuid)
        .then((response) => {
            loadData('', 10, 0);
            toast.success('Item deleted successfully.');
        })
        .catch((error) => {
            toast.error('Failed to delete item.');
        });
	};

	const handleEdit = (row) => {
		updateData(DataType.HOBBIES, row.id, row.name, row.description).then(()=> {
			loadData('', 10, 0);
		}).catch((error) => {
			toast.error('Failed to update item.');
		});
	};

	const loadData = React.useCallback((keyword, pageSize, page) => {
		setLoading(true);
		fetchData(DataType.HOBBIES, keyword, pageSize, page)
			.then((data) => {
				let rows = data.resp.map((element) => {
					return {
						id: element.uuid,
						name: element.symbol,
						precentage: element.precentage
					};
				});
				setData(rows);
				setCount(data.count);
				setCurrentPage(page);
				setLoading(false);
			})
			.catch(() => setLoading(false));
	});

	const onFilterModelChange = React.useCallback((filterModel) => {
		if (filterModel.quickFilterValues.length === 0) {
			loadData('', defaultPageSize, currentPage);
		} else {
			loadData(filterModel.quickFilterValues[0], defaultPageSize, currentPage);
		}
	});

	React.useEffect(() => {
		if (feedbacks) {
			setData(
				feedbacks.map((element) => {
					return {
						id: element.uuid,
						name: element.symbol,
						precentage: element.precentage
					};
				}),
			);
			setCount(5);
			setCurrentPage(0);
			setLoading(false);
		} else {
			loadData('', defaultPageSize, 0);
		}
	}, []);

	return (
		<>
			<h1>Hobbies</h1>
			<Table
				columns={columns}
				rows={data}
				pagination={true}
				paginationMode="server"
				pageSize={defaultPageSize}
				rowCount={count}
				page={currentPage}
				onPageChange={(newPage) => loadData(keyword, defaultPageSize, newPage)}
				onFilterModelChange={onFilterModelChange}
				loading={loading}
				handleChangeRowsPerPage={(e) => {
					setDefaultPageSize(parseInt(e, 10))
					loadData(keyword, parseInt(e, 10), 0);
				}}
                handleDelete={handleDelete}
				handleEditCell={handleEdit}
			/>
		</>
	);
};

export default HobbiesTable;
