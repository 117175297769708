import './App.css';
import {
  Routes,
  Route,
} from 'react-router-dom';
import AuthRouter from './routers/AuthRouter';
import DashboardRouter from './routers/DashboardRouter';

function App() {
  return (
    <div className='App'>
      <div className='AppGlass'>
        <Routes>
          <Route path='auth/*' element={<AuthRouter />} />
          <Route path='*' element={<DashboardRouter />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
