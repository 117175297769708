import React, { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { Visibility, Edit, Delete, Collections } from '@mui/icons-material';
import CardMediaGetFile from '../Shared/Media/CardMediaGetFile';

import {
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	IconButton,
	Typography,
} from '@mui/material';

const ServiceCard = (props) => {
	return (
		<Card
			sx={{
				maxWidth: 345,
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}
		>
			<CardHeader title={props.title} subheader={props.formattedCreatedAt} />
			<CardMediaGetFile
				component="img"
				height="200"
				src={props.image.url}
				isimage={props.image.isImage}
				mimetype={
					props.image.mime[0] === '.'
						? props.image.mime.substring(1)
						: props.image.mime
				}
			/>
			<CardContent>
				<Typography variant="body2" color="text.secondary">
					{props.description}
				</Typography>
			</CardContent>
			<CardActions disableSpacing>
				<IconButton
					onClick={props.onViewClick}
					aria-label={
						props.isSubService ? 'view companies' : 'view sub services'
					}
				>
					<Visibility color="primary" />
				</IconButton>
				<IconButton onClick={props.onEditClick} aria-label="edit">
					<Edit color="primary" />
				</IconButton>
				<IconButton
					sx={{ marginLeft: 'auto' }}
					aria-label="delete"
					onClick={props.onDeleteClick}
				>
					<Delete color="error" />
				</IconButton>
			</CardActions>
		</Card>
	);
};

export default ServiceCard;
