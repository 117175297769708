import axios, { METHODS } from '../../utilities/axios';
import {
	ADD_SERVICE,
	DELETE_SERVICE,
	GET_SERVICES,
	UPDATE_SERVICE,
	UPLOAD_SERVICE_IMAGE,
} from '../../constants/endpoints.api';
import { toast } from 'react-toastify';

export const fetchServices = (keyword, pageSize, pageNumber, withSub) => {
	return axios(METHODS.GET, GET_SERVICES, undefined, {
		keyword,
		pageSize,
		pageNumber,
		withSub,
	})
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
};

export const deleteService = (uuid) => {
	return axios(METHODS.DELETE, DELETE_SERVICE, { uuid })
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0) {
				return Promise.reject(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			} else if (error.error) {
				return Promise.reject(error.error);
			} else {
				return Promise.reject('Something went wrong.');
			}
		});
};

export const handleServiceAdd = (values, imageUUID) => {
	const { name, description, color, uuid } = values;
	return axios(METHODS.POST, ADD_SERVICE, {
		name,
		description,
		color: color.hex,
		uuid,
		file: imageUUID,
	})
		.then(async (response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.code === 0)
				return Promise.reject(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) return Promise.reject(error.error);
			else return Promise.reject('Something went wrong.');
		});
};

export const handleServiceUpdate = (values, imageUUID) => {
	const { name, description, color, uuid } = values;
	return axios(METHODS.PUT, UPDATE_SERVICE, {
		name,
		description,
		color: color.hex,
		uuid,
		file: imageUUID,
	})
		.then(async (response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.code === 0)
				return Promise.reject(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) return Promise.reject(error.error);
			else return Promise.reject('Something went wrong.');
		});
};

export const uploadServiceImage = (image) => {
	var formData = new FormData();
	formData.append('image', image);

	return axios(METHODS.POST, UPLOAD_SERVICE_IMAGE, formData, undefined, {
		'Content-Type': 'multipart/form-data',
	})
		.then(async (response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.code === 0)
				return Promise.reject(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) return Promise.reject(error.error);
			else return Promise.reject('Something went wrong.');
		});
};
