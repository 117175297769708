import Cookies from 'universal-cookie';
import history from './history';
import axios, { METHODS } from './axios';
import { LOGOUT_ENDPOINT } from '../constants/endpoints.api';
import { LOGIN } from '../constants/routes.api';
import { toast } from 'react-toastify';
import {socket} from './socket';

const logOut = () => {
	const cookies = new Cookies();

	axios(
		METHODS.POST,
		LOGOUT_ENDPOINT,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
	)
		.then(async () => {
			socket.disconnect();
			cookies.remove('accessToken', { path: '/', sameSite: 'strict' });
			cookies.remove('refreshToken', { path: '/', sameSite: 'strict' });
			toast.success(
				'You\'re logged out.',
			);
			history.push(LOGIN);
		})
		.catch(() => {
			toast.error(
				'Something Went Wrong',
			);
			cookies.remove('accessToken', { path: '/', sameSite: 'strict' });
			cookies.remove('refreshToken', { path: '/', sameSite: 'strict' });
			history.push(LOGIN);			
		});
};

export default logOut;

