import React from 'react';
import Table from '../Table/Table';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
	setCompanies,
	setCompanyFormOpen,
	setCompanyToEdit,
	setCount,
	setCurrentPage,
	setKeyword,
	setLoading,
	setNextPage,
} from '../../store/companiesTable';
import { deleteCompany, fetchAllCompanies } from './companyService';
import { toast } from 'react-toastify';
import { Dialog, DialogContent, Rating } from '@mui/material';
import CompanyForm from './CompanyForm';

const columns = [
	{ field: 'uuid', headerName: 'ID', width: 70, hide: true },
	{ field: 'name', headerName: 'Name', width: 250 },
	{ field: 'description', headerName: 'Description', width: 250 },
	{ field: 'email', headerName: 'Email', width: 250 },
	{ field: 'phoneNumber', headerName: 'Phone number', width: 250 },
	{
		field: 'userRating',
		headerName: 'User rating',
		width: 250,
		renderCell: (params) => <Rating readOnly value={params.row.userRating} />,
	},
	{
		field: 'serviceRating',
		headerName: 'Service rating',
		width: 250,
		renderCell: (params) => (
			<Rating readOnly value={params.row.serviceRating} />
		),
	},
	{
		field: 'createdAt',
		headerName: 'Creation Date',
		width: 130,
		valueGetter: (params) =>
			new Date(params.row.createdAt).toLocaleDateString(),
	},
];

const CompaniesTable = ({ users }) => {
	const theme = useTheme();

	const dispatch = useDispatch();

	const companies = useSelector((state) => state.companiesTable.companies);
	const loading = useSelector((state) => state.companiesTable.loading);
	const nextPage = useSelector((state) => state.companiesTable.nextPage);
	const count = useSelector((state) => state.companiesTable.count);
	const currentPage = useSelector((state) => state.companiesTable.currentPage);
	const keyword = useSelector((state) => state.companiesTable.keyword);
	const companyFormOpen = useSelector(
		(state) => state.companiesTable.companyFormOpen,
	);
	const companyToEdit = useSelector(
		(state) => state.companiesTable.companyToEdit,
	);

	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const defaultPageSize = 10;

	const handleDelete = (uuid) => {
		deleteCompany(uuid)
			.then((response) => {
				loadData('', defaultPageSize, 0);
				toast.success('Company deleted successfully.');
			})
			.catch((error) => {
				toast.error('Failed to delete company.');
			});
	};

	const handleEdit = (row) => {
		dispatch(setCompanyToEdit(row));
		dispatch(setCompanyFormOpen(true));
	};

	const loadData = React.useCallback((keyword, pageSize, page) => {
		dispatch(setLoading(true));
		fetchAllCompanies(keyword, pageSize, page)
			.then((data) => {
				let rows = data.resp.map((element) => {
					return {
						id: element.uuid,
						name: element.name,
						description: element.description,
						email: element.email,
						phoneNumber: element.phoneNumber,
						tags: element.tags,
						userRating: element.userRate ?? 0,
						serviceRating: element.serviceRate ?? 0,
						createdAt: element.createdAt,
						images: element.images,
						services: element.companyServiceUuids ?? [],
					};
				});
				dispatch(setCompanies(rows));
				dispatch(setNextPage(data.next));
				dispatch(setCount(data.count));
				dispatch(setCurrentPage(page));
				dispatch(setLoading(false));
			})
			.catch(() => dispatch(setLoading(false)));
	});

	const onFilterModelChange = React.useCallback((filterModel) => {
		dispatch(setKeyword(filterModel.quickFilterValues[0]));
		if (filterModel.quickFilterValues.length === 0) {
			loadData('', defaultPageSize, currentPage);
		} else {
			loadData(filterModel.quickFilterValues[0], defaultPageSize, currentPage);
		}
	});

	React.useEffect(() => {
		loadData('', defaultPageSize, 0);
	}, []);

	return (
		<>
			<h1>Companies</h1>
			<Table
				columns={columns}
				rows={companies}
				pagination={true}
				paginationMode="server"
				pageSize={defaultPageSize}
				rowCount={count}
				page={currentPage}
				onPageChange={(newPage) => loadData(keyword, defaultPageSize, newPage)}
				onFilterModelChange={onFilterModelChange}
				// handleOnRowClick={onRowClick}
				loading={loading}
				handleDelete={handleDelete}
				handleEdit={handleEdit}
			/>
			<Dialog
				open={companyFormOpen}
				onClose={() => dispatch(setCompanyFormOpen(false))}
				aria-labelledby="edit company"
			>
				<DialogContent>
					<CompanyForm
						onSuccess={() => {
							dispatch(setCompanyFormOpen(false));
							loadData('', defaultPageSize, 0);
						}}
						{...companyToEdit}
						title={companyToEdit?.name}
						useTagsAsUUIDs
						showServicesInput
						uuid={companyToEdit?.id}
						isUpdateForm={companyToEdit !== null}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default CompaniesTable;
