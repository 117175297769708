import { Skeleton } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addImage, setSendRequest } from '../../../store/images';
import getFile from '../../../utilities/getFile';

const Media = (props) => {
	const image = useSelector((state) => {
		return state.images.value[props.src];
	});
	
	const added = useSelector((state) => {
		for (let i = 0 ; i < state.images.sentRequest.length; i++) {
			if (state.images.sentRequest[i] === props.src) {
				return true;
			}
		}
		return false;
	});

	const dispatch = useDispatch();
	const [loading, setLoading] = React.useState(true);
	React.useEffect(() => {
		setLoading(true);
		if (!image && !added) {
			dispatch(setSendRequest(props.src));
			getFile(props.src, props.mimeType, props.isimage).then((res) => {
				let imgObj = {};
				imgObj[props.src] = res;
				dispatch(addImage(imgObj));
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	}, [props.src, image]);

	return props.background === true ? (
		loading ? (
			<Skeleton
				variant="rectangular"
				style={props.style}
				className={props.className}
			/>
		) : (
			<div
				className={props.className}
				style={{ backgroundSize: 'cover', backgroundImage: `url('${image}')` }}
				loading="lazy"
			>
				{props.children}
			</div>
		)
	) : loading ? (
		<Skeleton
			variant="rectangular"
			style={{ height: '300px', ...props.style }}
			className={props.className}
		/>
	) : (
		<img
			className={props.className}
			style={{ ...props.style, cursor: 'thumb' }}
			src={image}
			loading="lazy"
			onClick={props.onClick}
		/>
	);
};

export default Media;
