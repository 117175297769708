import axios, { METHODS } from '../../utilities/axios';
import {
	ACTIVATE_USER,
	DEACTIVATE_USER,
	DECREASE_PRIVILAGE,
	ESCELATE_PRIVILAGE,
	GET_USERS_ENDPOINT,
	GET_USER_PROFILE_ENDPOINT,
} from '../../constants/endpoints.api';
import { toast } from 'react-toastify';

export const fetchUsers = (keyword, pageSize, pageNumber) => {
	return axios(
		METHODS.GET,
		GET_USERS_ENDPOINT,
		undefined,
		{
			keyword,
			pageSize,
			pageNumber,
		},
		undefined,
		undefined,
		undefined,
	)
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
};

export const fetchUserProfile = (uuid) => {
	return axios(
		METHODS.GET,
		GET_USER_PROFILE_ENDPOINT,
		undefined,
		{
			uuid,
		},
		undefined,
		undefined,
		undefined,
	)
		.then((response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
};

export const changeUserRole = (uuid, activate) => {
	let endpoint = DECREASE_PRIVILAGE;
	if (activate) {
		endpoint = ESCELATE_PRIVILAGE;
	}

	return axios(METHODS.PUT, endpoint, { uuid })
		.then((response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
};

export const changeUserActivation = (uuid, setAsAdmin) => {
	let endpoint = ACTIVATE_USER;
	if (!setAsAdmin) {
		endpoint = DEACTIVATE_USER;
	}

	return axios(METHODS.PUT, endpoint, {
		uuid,
	})
		.then((response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
};
