import { configureStore } from '@reduxjs/toolkit';
import analyticsReducers from './analytics';
import sidebarReducers from './sidebar';
import servicesReducers from './services';
import imagesReducers from './images';
import companiesReducer from './company';
import companiesTableReducer from './companiesTable';
import postReducer from './post';


export default configureStore({
	reducer: {
		sidebar: sidebarReducers,
		analytics: analyticsReducers,
		services: servicesReducers,
		images: imagesReducers,
		companies: companiesReducer,
		companiesTable: companiesTableReducer,
		post: postReducer,
	},
});
