import { createSlice } from '@reduxjs/toolkit';

const companiesSlice = createSlice({
	name: 'companies',
	initialState: {
		companies: [],
		loading: true,
		nextPage: 0,
		deleteModalOpen: false,
		companyToManipulate: null,
		companyFormOpen: false,
		form: {
			loading: false,
		},
		companyImagesDialogOpen: false,
	},
	reducers: {
		setCompanies: (state, action) => {
			state.companies = action.payload;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setNextPage: (state, action) => {
			state.nextPage = action.payload;
		},
		setDeleteModalOpen: (state, action) => {
			state.deleteModalOpen = action.payload;
		},
		setCompanyToManipulate: (state, action) => {
			state.companyToManipulate = action.payload;
		},
		setCompanyFormOpen: (state, action) => {
			state.companyFormOpen = action.payload;
		},
		setFormLoading: (state, action) => {
			state.form.loading = action.payload;
		},
		setCompanyImagesDialogOpen: (state, action) => {
			state.companyImagesDialogOpen = action.payload;
		},
	},
});

export const {
	setCompanies,
	setLoading,
	setNextPage,
	setDeleteModalOpen,
	setCompanyToManipulate,
	setCompanyFormOpen,
	setFormLoading,
	setCompanyImagesDialogOpen,
} = companiesSlice.actions;

export default companiesSlice.reducer;
