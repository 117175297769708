import { fetchMapData, fetchCountries, addPolygon, addCity } from "./mapService";
import React from "react";
import Loader from '../Shared/Loading';
import ReactGeoJSON from 'react-geojson';
import {googleMapApiKey} from '../../utilities/axios';
import MapTable from "./MapTable";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	MenuItem,
  Select,
	TextField,
} from '@mui/material';

const Map = () => {

  const [loading, setIsLoading] = React.useState(true);
  const [countries, setCountries] = React.useState([]);
  const [polygonName, setPolygonName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [polygons, setPolygons] = React.useState({});
  const [country, setCountry] = React.useState("");

  const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

  const handleAddPolygon = () => {
		if (!polygonName || polygonName.length === 0 || !country || country.length == 0) {
			return;
		}
    
    sendAddCity(country, polygonName);

	};

  const handlePolygonChange = (event) => {
		setPolygonName(event.target.value);
	};
  
  const sendAddCity = React.useCallback((uuid, name)=> {
    
    addCity(uuid, name).then((response) => {
      sendAddPolygon(response.uuid, {
        features: [polygons.features[polygons.features.length - 1]],
        type: "FeatureCollection"
      });
    });

  });

  const sendAddPolygon = React.useCallback((uuid, coordinates)=> {
    addPolygon(uuid, coordinates).then(()=> {
      setOpen(false);
    })
  });

  const loadData = React.useCallback(() => {
		setIsLoading(true);
		fetchMapData()
			.then((response) => {
        console.log(response);
        let coordinates2 = response.map((element) => {
					return {
            ...element.polygon.features[0],
          };
				});        
        let originalCoordinates = [];

        for (let i of coordinates2) {
          if (Object.keys(i).length > 0) {
            originalCoordinates.push(i);
          }
        }

        setPolygons({
          features: originalCoordinates,
          type: "FeatureCollection"
        });
        
        fetchCountries(1000, 0, "").then((response)=> {
          let coun = response.resp.map((element) => {
            return {
              uuid: element.uuid,
              name: element.name
            }
          })
          setCountries(coun);
        })

				setIsLoading(false);
        
			})
			.catch(() => {
				setIsLoading(false);
			});
	});

	React.useEffect(() => {
		loadData();
	}, []);

  const mapStyles = [];
  const areaStyles = [];

  const handle = (data) => {
    setPolygons(data);
    handleOpen();
  }

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };


  return (
   
  <div style={{ height: "400px", marginTop:100}}>
  {loading ? (
          <Loader size={50}/>
        ) : (
          <>
          <ReactGeoJSON
          apiKey={googleMapApiKey}
          existingArea={polygons}
          onSave={(data) => {handle(data);}}
          mapStyles={mapStyles}
          areaStyles={areaStyles}
          zoom={12}
          center={{ lng: 55.166559219360344, lat: 25.12446009455572 }}
        >
        </ReactGeoJSON>
        <MapTable></MapTable>
        </>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
				<DialogTitle>Add New polygon</DialogTitle>
				<DialogContent>
          <DialogContentText> please select a city :</DialogContentText>
          <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={country}
              label="country"
              onChange={handleCountryChange}
            >
            {
                countries.map((element) => {
                  return (
                    <MenuItem value={element.uuid} key={element.uuid}>{element.name}</MenuItem>
                  )
                })
              }
          </Select>
					<DialogContentText> please enter the entered polygon city name :</DialogContentText>
          <TextField
						autoFocus
						margin="dense"
						id="city_name"
						label="city_name"
						type="text"
						fullWidth
						variant="standard"
						required
						value={polygonName}
						onChange={handlePolygonChange}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleAddPolygon}>Add Polygon</Button>
				</DialogActions>
			</Dialog>
  </div>
)
}

export default Map