import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react-dom/test-utils';

const analyticsSlice = createSlice({
	name: 'analytics',
	initialState: {
		value: [],
	},
	reducers: {
		setAnalytics: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { setAnalytics } = analyticsSlice.actions;

export default analyticsSlice.reducer;
