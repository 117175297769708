import React from 'react';
import './Analytics.css';
import Card from './Card/Card';
import fetchStats from './analyticsService';
import {
	Button,
	Card as MaterialCard,
	CardActions,
	CardContent,
	CardHeader,
	Dialog,
	DialogContent,
	Grid,
	Typography,
	useMediaQuery,
} from '@mui/material';
import {
	MailOutlineOutlined,
	PersonOutlineOutlined,
	BusinessOutlined,
	ShoppingBagOutlined,
	SportsBasketballOutlined,
	TranslateOutlined,
	TagOutlined,
	RectangleOutlined,
	Feedback
} from '@mui/icons-material';
import Loader from '../Shared/Loading';
import { socket } from '../../utilities/socket';
import CardMediaGetFile from '../Shared/Media/CardMediaGetFile';
import Carousel from 'react-material-ui-carousel';
import AvatarGetFile from '../Shared/Media/AvatarGetFile';
import User from '../Users/user';
import { useTheme } from '@mui/material/styles';
import { setAnalytics } from '../../store/analytics';
import { useDispatch, useSelector } from 'react-redux';
import Chart from "react-apexcharts";
import FeedbackStats from '../FeedbackStats/FeedbackStats';

const Analytics = () => {
	const [loading, setLoading] = React.useState(true);
	const [topUsers, setTopUsers] = React.useState([]);
	const [topPosts, setTopPosts] = React.useState([]);
	const [femaleCount, setFemaleCount] = React.useState(0);
	const [maleCount, setMaleCount] = React.useState(0);
	const [marriedCount, setMarriedCount] = React.useState(0);
	const [singleCount, setSingleCount] = React.useState(0);
	const [citiesNames, setCitiesNames] = React.useState([]);
	const [citiesCounts, setCitiesCounts] = React.useState([]);
	const [feedbackCount, setFeedbackCount] = React.useState(0);
	const [selectedUserId, setSelectedUserId] = React.useState(-1);
	const [open, setOpen] = React.useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const analyticsData = useSelector((state) => state.analytics.value);
	const dispatch = useDispatch();

	const handleUserOnClick = (id) => {
		setSelectedUserId(id);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	React.useEffect(() => {
		socket.on('post-added', (value) => {
			updateCount('postsCount', value);
		});
		socket.on('user-added', (value) => {
			updateCount('usersCount', value);
		});
		socket.on('company-added', (value) => {
			updateCount('companiesCount', value);
		});
		socket.on('service-added', (value) => {
			updateCount('servicesCount', value);
		});
		socket.on('sub-service-added', (value) => {
			updateCount('subServicesCount', value);
		});
		socket.on('hobbie-added', (value) => {
			updateCount('hobbiesCount', value);
		});
		socket.on('language-added', (value) => {
			updateCount('languagesCount', value);
		});
		socket.on('tag-added', (value) => {
			updateCount('tagsCount', value);
		});
		socket.on('polygon-added', (value) => {
			updateCount('polygonCount', value);
		});

		fetchStats()
			.then((stats) => {
				dispatch(
					setAnalytics([
						{
							symbol: 'usersCount',
							title: 'Users',
							color: {
								backGround: 'linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)',
								boxShadow: '0px 10px 20px 0px #e0c6f5',
							},
							value: stats.usersCount,
							series: [
								{
									name: 'Users',
									data: stats.userChart.map((el) => el.count),
								},
							],
							seriesDates: stats.userChart.map((el) =>
								el.date
									.replace('th', '')
									.replace('st', '')
									.replace('nd', '')
									.replace('rd', ''),
							),
						},
						{
							symbol: 'feedbackStats',
							title: 'Feedback',
							color: {
								backGround: 'linear-gradient(rgb(147 170 212) 0%, rgb(70 154 121) 100%)',
								boxShadow: '0px 10px 20px 0px #e0c6f5',
							},
							value: stats.feedbackCount,
							series: [
								{
									name: 'Feedbacks',
									data: stats.feedbackStats.map((el) => el.count),
								},
							],
							seriesDates: stats.feedbackStats.map((el) =>
								el.date
									.replace('th', '')
									.replace('st', '')
									.replace('nd', '')
									.replace('rd', ''),
							),
						},
						{
							symbol: 'postsCount',
							title: 'Posts',
							color: {
								backGround:
									'linear-gradient(rgb(255 103 124) 0%, rgb(243 132 132) 100%)',
								boxShadow: '0px 10px 20px 0px #e0c6f5',
							},
							value: stats.postsCount,
							series: [
								{
									name: 'Posts',
									data: stats.postChart.map((el) => el.count),
								},
							],
							seriesDates: stats.postChart.map((el) =>
								el.date
									.replace('th', '')
									.replace('st', '')
									.replace('nd', '')
									.replace('rd', ''),
							),
						},
						{
							symbol: 'companiesCount',
							title: 'Companies',
							color: {
								backGround:
									'linear-gradient(rgb(255 159 103) 0%, rgb(243 173 132) 100%)',
								boxShadow: '0px 10px 20px 0px #e0c6f5',
							},
							value: stats.companiesCount,
						},
						{
							symbol: 'servicesCount',
							title: 'Services',
							color: {
								backGround:
									'linear-gradient(rgb(255 208 103) 0%, rgb(243 234 132) 100%)',
								boxShadow: '0px 10px 20px 0px #e0c6f5',
							},
							value: stats.servicesCount,
						},
						{
							symbol: 'subServicesCount',
							title: 'Sub Services',
							color: {
								backGround:
									'linear-gradient(rgb(85 207 94) 0%, rgb(97 173 92) 100%)',
								boxShadow: '0px 10px 20px 0px #e0c6f5',
							},
							value: stats.subServicesCount,
						},
						{
							symbol: 'hobbiesCount',
							title: 'Hobbies',
							color: {
								backGround:
									'linear-gradient(rgb(103 255 255) 0%, rgb(132 202 243) 100%)',
								boxShadow: '0px 10px 20px 0px #e0c6f5',
							},
							value: stats.hobbiesCount,
						},
						{
							symbol: 'languagesCount',
							title: 'Languages',
							color: {
								backGround:
									'linear-gradient(rgb(103 157 255) 0%, rgb(132 197 243) 100%)',
								boxShadow: '0px 10px 20px 0px #e0c6f5',
							},
							value: stats.languagesCount,
						},
						{
							symbol: 'tagsCount',
							title: 'Tags',
							color: {
								backGround:
									'linear-gradient(rgb(255 103 236) 0%, rgb(237 167 225) 100%)',
								boxShadow: '0px 10px 20px 0px #e0c6f5',
							},
							value: stats.tagsCount,
						},
						{
							symbol: 'polygonCount',
							title: 'Polygons',
							color: {
								backGround:
									'linear-gradient(rgb(227 60 60) 0%, rgb(191 36 36) 100%)',
								boxShadow: '0px 10px 20px 0px #e0c6f5',
							},
							value: stats.polygonCount,
						},
					]),
				);
				setTopUsers(stats.topUsers);
				setTopPosts(stats.topPosts);
				setLoading(false);
				setMaleCount(Number(stats.maleCount));
				setFemaleCount(Number(stats.femaleCount));
				setMarriedCount(Number(stats.marriedCount));
				setSingleCount(Number(stats.singleCount));
				setCitiesNames(stats.cityChart.cityNames);
				setCitiesCounts(stats.cityChart.cityCount);
				setFeedbackCount(Number(stats.feedbackCount));

			})
			.catch();

		const updateCount = (key, value) => {
			if (analyticsData.length === 0) {
				return;
			}

			let newData = analyticsData.map((item) => {
				let newItem = { ...item };
				if (newItem.symbol === key) {
					newItem.value = value[key];
				}
				return newItem;
			});
			dispatch(setAnalytics(newData));
		};

		return () => {
			socket.off('post-added');
			socket.off('user-added');
			socket.off('company-added');
			socket.off('service-added');
			socket.off('sub-service-added');
			socket.off('hobbie-added');
			socket.off('language-added');
			socket.off('tag-added');
			socket.off('polygon-added');
		};
	}, []);

	
	function replaceAll(string, search, replace) {
		return string.split(search).join(replace);
	}

	const replaceUUIDS = (str, mentionedUsers) => {
		for (let i = 0; i < mentionedUsers.length; i++) {
			str = replaceAll(
				str,
				`@(${mentionedUsers[i].uuid})[]`,
				`<b>${mentionedUsers[i].name}</b>`,
			);
		}
		return str;
	};

	let series1 = [maleCount, femaleCount];
	let labels1 = ['Male', 'Female'];
	let options1= {labels:labels1};
	let series2 = [marriedCount, singleCount];
	let labels2 = ['Married', 'Single'];
	let options2 = {labels:labels2};
	let options = {
        chart: {
          id: "basic-bar"
        },
        xaxis: {
          categories: citiesNames
        }
	}
	let series = [
        {
          name: "number of users in the city",
          data: citiesCounts
        }
	]
	return (
		<>
			<h1>Analytics</h1>
			<Grid container direction="column" sx={{ overFlow: 'auto' }}>
				<Grid item container spacing={4}>
					{loading && <Loader />}
					{!loading &&
						analyticsData.map((card, id) => {
							let route = "/user";
							let icon = PersonOutlineOutlined;
							if (card.symbol == 'postsCount') {
								icon = MailOutlineOutlined;
								route = '/feed'
							} else if (card.symbol == 'companiesCount') {
								icon = BusinessOutlined;
								route = "/companies";
							} else if (card.symbol == 'servicesCount') {
								icon = ShoppingBagOutlined;
								route = "/services";
							} else if (card.symbol == 'subServicesCount') {
								icon = ShoppingBagOutlined;
								route = "/services";
							} else if (card.symbol == 'hobbiesCount') {
								icon = SportsBasketballOutlined;
								route = "/data-panels";
							} else if (card.symbol == 'languagesCount') {
								icon = TranslateOutlined;
								route = "/data-panels";
							} else if (card.symbol == 'tagsCount') {
								icon = TagOutlined;
								route = "/data-panels";
							} else if (card.symbol == 'polygonCount') {
								icon = RectangleOutlined;
								route = "/map";
							} else if (card.symbol == 'feedbackStats') {
								icon = Feedback;
								route = "/feedback";
							}

							return (
								<Grid item md={4} className="parentContainer" key={id}>
									<Card
										title={card.title}
										color={card.color}
										value={card.value}
										png={icon}
										barValue={card.barValue}
										series={card.series}
										seriesDates={card.seriesDates}
										route = {route}
									/>
								</Grid>
							);
						})}
				</Grid>
				<Grid container direction="column" sx={{ overFlow: 'auto' }}>
					{!loading && 
					<Chart options={options} series={series} type="bar"/>
					}
				</Grid>
				<Grid container spacing={4} marginBottom={6} marginTop={6}>
					{!loading && (
						<>
							<Grid
								container
								item
								md={6}
								direction="column"
								alignContent="center"
							>
								<Typography variant="h5">Top Users</Typography>
								<Grid item md={8} sx={{ textAlign: 'center', width: '70%' }}>
									<Carousel
										fullHeightHover={true}
										animation="slide"
										navButtonsAlwaysVisible={true}
										style={{ textAlign: 'center' }}
										navButtonsProps={{
											style: {
												backgroundColor: 'cornflowerblue',
												borderRadius: 0,
											},
										}}
										sx={{
											textAlign: 'center',
											marginTop: 5,
											minHeight: '400px',
										}}
										navButtonsWrapperProps={{
											style: {
												top: 'calc(50% - 30px)',
												height: 'unset',
											},
										}}
									>
										{topUsers.map((user) => (
											<MaterialCard
												sx={{
													width: '%100',
													textAlign: 'center',
													height: '400px',
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'space-between',
												}}
												key={user.uuid}
											>
												<CardMediaGetFile
													component="img"
													height="200"
													src={user.image.url}
													isimage={user.image.isImage}
													alt={user.fullName}
													mimetype={
														user.image.mime[0] === '.'
															? user.image.mime.substring(1)
															: user.image.mime
													}
												/>
												<CardContent>
													<Typography gutterBottom variant="h5" component="div">
														{user.fullName}
													</Typography>
												</CardContent>
												<CardActions>
													<Button
														size="small"
														onClick={() => handleUserOnClick(user.uuid)}
													>
														View More
													</Button>
												</CardActions>
											</MaterialCard>
										))}
									</Carousel>
								</Grid>
							</Grid>
							<Grid
								container
								item
								md={6}
								direction="column"
								alignContent="center"
							>
								<Typography variant="h5">Top Posts</Typography>
								<Grid item md={8} sx={{ textAlign: 'center', width: '70%' }}>
									<Carousel
										fullHeightHover={true}
										autoPlay={false}
										animation="slide"
										navButtonsAlwaysVisible={true}
										style={{ textAlign: 'center' }}
										navButtonsProps={{
											style: {
												backgroundColor: 'cornflowerblue',
												borderRadius: 0,
											},
										}}
										sx={{
											textAlign: 'center',
											marginTop: 5,
											minHeight: '400px',
										}}
										navButtonsWrapperProps={{
											style: {
												top: 'calc(50% - 30px)',
												height: 'unset',
											},
										}}
									>
										{topPosts.map((post) => (
											<MaterialCard
												sx={{ width: '100%', minHeight: '400px' }}
												key={post.uuid}
											>
												<CardHeader
													avatar={
														<AvatarGetFile
															src={post.user.image.url}
															mimeType={
																post.user.image.mime[0] === '.'
																	? post.user.image.mime.substring(1)
																	: post.user.image.mime
															}
															isimage={post.user.image.isImage}
														/>
													}
													title={post.user.name}
													subheader={post.formattedCreatedAt}
												/>
												<Carousel autoPlay={true}>
													{post.attachments.map((attach) => (
														<CardMediaGetFile
															key={attach.uuid}
															component="img"
															height="200"
															src={attach.url}
															mimetype={
																attach.mime[0] === '.'
																	? attach.mime.substring(1)
																	: attach.mime
															}
															isimage={attach.isImage}
															alt="Paella dish"
														/>
													))}
												</Carousel>
												<CardContent>
													<Typography variant="body2" color="text.secondary">
														<span dangerouslySetInnerHTML={{ __html:replaceUUIDS(post.post, post.mentionedUsers)}}></span>
													</Typography>
												</CardContent>
											</MaterialCard>
										))}
									</Carousel>
								</Grid>{' '}
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				fullWidth={true}
				maxWidth="lg"
			>
				<DialogContent sx={{ padding: 0 }}>
					<User userId={selectedUserId}></User>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default Analytics;
