import axios, { METHODS } from '../../utilities/axios';
import {
	ADD_COMPANY,
	ADD_COMPANY_SERVICE,
	ADD_COMPANY_TAGS,
	DELETE_COMPANY,
	GET_ALL_COMPANIES,
	GET_ALL_SUBSERVICES,
	GET_SERVICE_COMPANIES,
	UPDATE_COMPANY,
	UPLOAD_COMPANY_IMAGE,
} from '../../constants/endpoints.api';
import { toast } from 'react-toastify';

export const fetchCompanies = (uuid, keyword, pageSize, pageNumber) => {
	return axios(METHODS.GET, GET_SERVICE_COMPANIES, undefined, {
		uuid,
		keyword,
		pageSize,
		pageNumber,
	})
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
};

export const deleteCompany = (uuid) => {
	return axios(METHODS.DELETE, DELETE_COMPANY, { uuid })
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0) {
				return Promise.reject(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			} else if (error.error) {
				return Promise.reject(error.error);
			} else {
				return Promise.reject('Something went wrong.');
			}
		});
};

export const handleCompanyAdd = (values, imageUUIDs) => {
	const { name, description, email, phoneNumber } = values;
	return axios(METHODS.POST, ADD_COMPANY, {
		name,
		description,
		email,
		phoneNumber,
		files: imageUUIDs,
	})
		.then(async (response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.code === 0)
				return Promise.reject(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) return Promise.reject(error.error);
			else return Promise.reject('Something went wrong.');
		});
};

export const handleCompanyUpdate = (values, imageUUIDs) => {
	const { name, description, email, phoneNumber, uuid } = values;
	return axios(METHODS.PUT, UPDATE_COMPANY, {
		name,
		description,
		email,
		phoneNumber,
		uuid,
		files: imageUUIDs,
	})
		.then(async (response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.code === 0)
				return Promise.reject(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) return Promise.reject(error.error);
			else return Promise.reject('Something went wrong.');
		});
};

export const uploadCompanyImage = (images) => {
	var formData = new FormData();
	Array.from(images).forEach((image) => formData.append('images', image));

	return axios(METHODS.POST, UPLOAD_COMPANY_IMAGE, formData, undefined, {
		'Content-Type': 'multipart/form-data',
	})
		.then(async (response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.code === 0)
				return Promise.reject(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) return Promise.reject(error.error);
			else return Promise.reject('Something went wrong.');
		});
};

export const handleCompanyAddService = (uuid, servicesUUIDs) => {
	return axios(METHODS.POST, ADD_COMPANY_SERVICE, {
		uuid,
		services: servicesUUIDs,
	})
		.then(async (response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.code === 0)
				return Promise.reject(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) return Promise.reject(error.error);
			else return Promise.reject('Something went wrong.');
		});
};

export const handleCompanyAddTag = (uuid, tagUUIDs) => {
	return axios(METHODS.POST, ADD_COMPANY_TAGS, {
		uuid,
		tags: tagUUIDs,
	})
		.then(async (response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.code === 0)
				return Promise.reject(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) return Promise.reject(error.error);
			else return Promise.reject('Something went wrong.');
		});
};

export const fetchAllCompanies = (keyword, pageSize, pageNumber) => {
	return axios(METHODS.GET, GET_ALL_COMPANIES, undefined, {
		keyword,
		pageSize,
		pageNumber,
	})
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
};

export const fetchAllSubServices = (keyword, pageSize, pageNumber) => {
	return axios(METHODS.GET, GET_ALL_SUBSERVICES, undefined, {
		keyword,
		pageSize,
		pageNumber,
	})
		.then(async (response) => {
			return response.data.data;
		})
		.catch((error) => {
			if (error.code === 0)
				toast.error(
					'Something went wrong. Make sure you are connected to the internet.',
				);
			else if (error.error) toast.error(error.error);
			else toast.error('Something went wrong.');
		});
};
